import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

export const StyledAlertSnackbar = styled(Snackbar)`
  background-color: transparent;
  margin-top: 40px;
  border-radius: 0;
  color: #1976d2;
  box-shadow: none;
  padding: 20px;

  & .MuiSnackbarContent-root {
    background-color: #f2f2f2;
    color: #1976d2;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    border: 2px solid #1976d2;
    border-radius: 10px;
    padding: 0px 20px;
    align-items: center;
    width: 100%;
    max-width: 600px;
  }

  & .MuiSnackbarContent-message {
    display:flex;
    align-content: center;
  }

  & .icon {
    margin-right: 10px;
  }

`;