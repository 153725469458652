import axios from "axios";

export const sendErrorReport = async (error, id, userData) => {
  const apiUrl = 'https://us-central1-webinarstvus.cloudfunctions.net/sendErrorReport';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let path = window.location.href;
  if (error?.message.includes("[object Object]")) return;
  if (error?.message.includes("Request")) return;
  if (error?.message.includes("Network Error")) return;
  if (path.includes("localhost")) return;

  const errorDetails = {
    message: error?.message ? error.message : error.toString(),
    formData: error?.formValues || undefined,
    name: error?.name || undefined,
    stack: error?.stack || undefined,
    firebaseCode: error?.code || undefined,
    status: error?.response ? error.response.status : undefined,
    statusText: error?.response ? error.response.statusText : undefined,
  };

  const payload = {
    error: JSON.stringify(errorDetails),
    id,
    userData: JSON.stringify(userData) || undefined,
    path: window.location.href,
    deviceInfo: navigator.userAgent,
    time: new Date().toISOString(),
  };

  try {
    await axios.post(apiUrl, payload, config);
    console.log('Error report sent successfully');
  } catch (err) {
    console.log('Failed to send error report', err);
  }
}