import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hostData: {},
  hostChannelData: {},
};

const hostDataSlice = createSlice({
  name: "hostData",
  initialState,
  reducers: {
    setHostData(state, action) {
      state.hostData = action.payload;
    },
    setHostChannelData(state, action) {
      state.hostChannelData = action.payload;
    },

  },
});

export const { setHostData, setHostChannelData } = hostDataSlice.actions;

export default hostDataSlice.reducer;