import React, { useState, Suspense } from 'react';
import { CircularProgress } from '@mui/material';

const withChunkErrorHandling = (Component) => (props) => {
  const [chunkError, setChunkError] = useState(false);

  if (chunkError) {
    return <CircularProgress />;
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <Component {...props} setChunkError={setChunkError} />
    </Suspense>
  );
};

export default withChunkErrorHandling;