const ErrorButton = ({ goBack }) => {
  return (
    <div className="error-boundary-wrap">
      <div className="error-boundary-inner">
        <p style={{ fontSize: "32px" }}>
          Oh no, we've encountered an issue. Good news: Our engineers have been notified and are working on a resolution.
        </p>
        <button
          className="ripple-button"
          style={{ fontSize: "25px" }}
          onClick={() => {
            console.log("Navigating back");
            goBack();
          }}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ErrorButton;