import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  userData: {},
  user: {},
  accountPath: "",
  openAgreeToTerms: false,
  firebaseToken: "",
  isLoggedIn: false,
  inAccountPage: false,
  setChannelFormInfo: {},
  isAuthLoading: true,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserDataField: (state, action) => {
      const { field, value } = action.payload;
      state.userData[field] = value;
    },
    setAccountPath: (state, action) => {
      state.accountPath = action.payload;
    },
    setAgreeToTerms: (state, action) => {
      state.openAgreeToTerms = action.payload;
    },
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setIsAuthLoading: (state, action) => {
      state.isAuthLoading = action.payload;
    },
    setInAccountPage: (state, action) => {
      state.inAccountPage = action.payload;
    },
    setChannelFormInfo: (state, action) => {
      state.inAccountPage = action.payload;
    },
    resetUserState: () => initialUserState
  },
});

export const {
  setUserData,
  setUser,
  setAccountPath,
  setAgreeToTerms,
  setFirebaseToken,
  setIsLoggedIn,
  setInAccountPage,
  updateUserDataField,
  setChannelFormInfo,
  resetUserState,
  setIsAuthLoading
} = userSlice.actions;

export default userSlice.reducer;