import React, { useEffect, useState, lazy, Suspense } from "react";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Chart from "./Chart";
import WebinarsDisplayer from "./WebinarsDisplayer";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    "& .MuiPaper-root": {
      padding: 0,
    },
  },
}));

export default function WebinarReco({
  webinarData,
  token,
  user,
  logIn,
  hist,
  sched,
  libraryNotif,
  setLibraryNotif,
  reactGA,
}) {
  const classes = useStyles();
  // console.log("HEREREEEEEEEEE", props)
  return (
    <React.Fragment>
      <h1>WebinarReco</h1>

      <WebinarsDisplayer
        chartData={webinarData}
        logIn={logIn}
        promptConsent={promptConsent}
        token={token}
        user={user}
        hist={hist}
        sched={sched}
        libraryNotif={libraryNotif}
        setLibraryNotif={setLibraryNotif}
        reactGA={reactGA}
      />
      <br />
    </React.Fragment>
  );
}
