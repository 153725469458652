import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import TvIcon from "@mui/icons-material/Tv";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Recommended from "@mui/icons-material/RecommendOutlined";
import MailIcon from "@mui/icons-material/Mail";
import AddIcon from "@mui/icons-material/Add";
import ListAlt from "@mui/icons-material/ListAltOutlined";
import LiveTv from "@mui/icons-material/LiveTv";
import Setting from "@mui/icons-material/SettingsOutlined";
import Content from "@mui/icons-material/VideoLibraryOutlined";
import FAQ from "@mui/icons-material/HelpOutlineOutlined";
import Home from "@mui/icons-material/HomeOutlined";
import WebinarCounter from "./WebinarCounter";

import AccountIcon from "@mui/icons-material/AccountCircleOutlined";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import WebinarReco from "./WebinarReco";
import BackToTopButton from "./BackToTopButton";
import Search from "./Search";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AccountButton from "./AccountButton";
import Analytics from "@mui/icons-material/AssessmentOutlined";
import Customize from "@mui/icons-material/AutoFixHighOutlined";
import webinarApi from "./utils/zoomCorderApi";
import CustomAlert from "./CustomAlert";
import { Controller, useForm } from "react-hook-form";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import { yupResolver } from "@hookform/resolvers/yup";
import googleLogin from "./assests/googlelogo.svg";
import * as yup from "yup";
import { RequestModals } from "./styles/channelStyles";
import companyLogo from "./assests/MeetingTVLogo.png";
import { useSelector, useDispatch } from "react-redux";
import { AppBar, DrawerHeader, Drawer } from "./styles/menuStyles";
import {
  setUserData,
  setAccountPath,
  setFirebaseToken,
  setIsLoggedIn,
  setInAccountPage,
  resetUserState,
  setIsAuthLoading,
} from "./redux/slices/userDataSlice";
import {
  setChannelOffSuccess,
  setChannelOffMessage,
} from "./redux/slices/channelOffSuccessSlice";
import {
  setOpenSuccess,
  setActiveEventTab,
  setFirstTimeWalkthrough,
  setOpenLogin,
} from "./redux/slices/conditionalUiSlice";
import {
  setAllEvents,
  setFilteredWebinars,
  setSearchQuery,
  setWebinarEvent,
  resetWebinarState,
} from "./redux/slices/webinarEventSlice";

import CustomAlertChannelStatus from "./CustomAlertChannelStatus";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";
import SnackbarAlert from "./SnackbarAlert";
import { sendErrorReport } from "./utils/sendError";

const clientID = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

const formTitle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100% !Important",
  fontSize: "18px",
  borderRadius: "8px",
  marginBottom: "6px !important",
  "@media (max-width: 600px)": {
    marginBottom: "0px !important",
  },
};

function toObject(arr, keyval) {
  var rv = {};
  for (var i = 0; i < arr.length; ++i)
    rv[Object.values(arr[i])[0][keyval]] = arr[i];
  return rv;
}
function sortObjBySingle(obje, sortval) {
  if (obje) {
    var arr = Object.values(obje);
    arr = arr.filter(function (obj) {
      return (
        Object.values(obj)[0][sortval] > Date.now() &&
        !Object.values(obj)[0]["single"]
      );
    });
    var returnObj = toObject(arr, "jobid");
    return returnObj;
  }
}
var tempFirebaseIDToken;
var profile;
var userLoaded;
var tempLibraryNotif = 0;
var tempSched;
var tempHist;
var gotHist;
var resizeListener;

let userId;
var useruid = "";
var authD = "webinarstvus.firebaseapp.com";

console.log("Running:");
if (process.env.NODE_ENV === "development") {
  authD = "http://localhost:3000";
}

var firebaseConfig = {
  apiKey: "AIzaSyAfyR5mBU19e94Zx-J7n_mmDkxn-TQCq3A",
  authDomain: "webinarstvus.firebaseapp.com",
  databaseURL: "https://webinarstvus-default-rtdb.firebaseio.com",
  projectId: "webinarstvus",
  storageBucket: "webinarstvus.appspot.com",
  messagingSenderId: "432766562652",
  appId: "1:432766562652:web:69c997c9f767bb74201199",
  measurementId: "G-877PZN9NSN",
};
var firebase2;
if (firebase.apps.length < 2) {
  firebase2 = firebase.initializeApp(firebaseConfig, "webtv");
}
firebase2 = firebase.app("webtv");

export { firebase2 };
export async function handleResendClick() {
  const user = firebase2.auth().currentUser;

  if (user && !user.emailVerified) {
    try {
      await user.sendEmailVerification();
      console.log("Verification email sent");
    } catch (error) {
      console.error("Error sending verification email:", error);
      // HANDLE ERRORS HERE
    }
  }
}

const Menu = ({ reactGA }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [displayNav, setDisplayNav] = useState(true);

  const [user, handleUserChange] = useState(profile);
  const [firebaseIDToken, handleFirebaseIDTokenChange] = useState();
  const [setUserSucc, handleSetUserSuccChange] = useState(false);
  const [sched, setSched] = useState({});
  const [hist, setHist] = useState({});
  const [libraryNotif, setLibraryNotif] = useState(tempLibraryNotif);
  const [openCircular, setOpenCircular] = useState(false);
  const [allDataReceived, setAllDataReceived] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openFireSignup, setOpenFireSignup] = useState(false);
  const [refreshFinished, setRefreshFinished] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [hideGoogleLogin, setHideGoogleLogin] = useState(false);
  const [tokenCheck, settokenCheck] = useState(false);

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userData);
  const { isLoggedIn, isAuthLoading } = useSelector((state) => state.user);
  const inAccountPage = useSelector((state) => state.user.inAccountPage);
  const firebaseTokenAlt = useSelector((state) => state.user.firebaseToken);
  const hostIdWhenClaimed = useSelector(
    (state) => state.userHostData.hostIdWhenClaimed
  );
  const dummyWebinarData = useSelector(
    (state) => state.dummyWebinar.dummyWebinarData
  );
  const webinarData = useSelector((state) => state.event.allEvents);
  const token = useSelector((state) => state.user.firebaseToken);
  const openLogin = useSelector((state) => state.conditionalUi.openLogin);

  const handleFirstTimeWalkthrough = () =>
    dispatch(setFirstTimeWalkthrough(true));
  const handleOpenCircular = () => setOpenCircular(true);
  const handleCloseCircular = () => setOpenCircular(false);
  const handleOpenLogin = () => dispatch(setOpenLogin(true));
  const handleCloseLogin = () => {
    setIsInvalid(false);
    dispatch(setOpenLogin(false));
  };
  const handleOpenFireSignup = () => setOpenFireSignup(true);
  const handleCloseFireSignup = () => setOpenFireSignup(false);
  const handlerFunction = (setter, value) => {
    setter(!value);
  };
  const handleOpenSuccessLogout = () =>
    dispatch(setOpenSuccess({ open: true, message: "Logged out" }));
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  var firebaseUser;
  var exp = 0;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    if (userData) {
      if (Object.keys(userData).length) {
        dispatch(setIsLoggedIn(true));
        handleCloseCircular();
        if (typeof window !== "undefined" && window.localStorage) {
          window.localStorage.removeItem("yourKey");
        }
      }
    }
    var standaloneIos = window.navigator.standalone;
    var isInStandAloneChrome = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    var userAgent = window.navigator.userAgent.toLowerCase(),
      ios = /iphone|ipod|ipad/.test(userAgent);
    var isMobile = false;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      isMobile = true;
    }
    if (isMobile) {
      if ((ios && standaloneIos) || (!ios && isInStandAloneChrome)) {
        setHideGoogleLogin(true);
      }
    }

    // if(!standalone){
    // window.open(window.location.href, '_system');
    // }
  }, []);

  const loginSchema = yup.object().shape({
    email: yup.string().email().required("This field is required").trim(),
    loginPassword: yup
      .string()
      .min(6, "Minimum 6 charcters required")
      .max(64)
      .required("This field is required")
      .trim(),
  });

  const signupSchema = yup.object().shape({
    email: yup.string().email().required("This field is required").trim(),
    password: yup
      .string()
      .min(6, "Minimum 6 charcters required")
      .max(64)
      .required("This field is required")
      .trim(),
    firstName: yup.string().required("This field is required").trim(),
    lastName: yup.string().required("This field is required").trim(),
  });

  const signupDefaultValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const loginDefaultValues = {
    email: "",
    loginPassword: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    reset,
    trigger,
  } = useForm({
    mode: "all",
    defaultValues: loginDefaultValues,
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
  });

  const {
    control: signupControl,
    handleSubmit: signupHandleSubmit,
    formState: { errors: signupErrors },
    reset: signupReset,
  } = useForm({
    mode: "all",
    defaultValues: signupDefaultValues,
    resolver: yupResolver(signupSchema),
  });

  const { pathname, search } = useLocation();
  if (firebaseIDToken && !tempFirebaseIDToken) {
    tempFirebaseIDToken = firebaseIDToken;
  }

  let isRegisterPath = pathname.includes("/register");
  let isNewCreatePath = pathname.includes("/account/create-event");
  let isJoinPath = search.includes("MID");
  let isNewVisitor = pathname.includes("/create-event");

  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/drive.file");

  const logIn = () => {
    setIsGoogle(true);
    reactGA.event({
      label: "Google Login Initiated",
      category: "Login",
      action: "Google Login Initiated",
    });
    useGLogin();
  };
  const useGLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      successresponseGoogle(codeResponse);
      handleOpenCircular();
    },
    onError: () => {
      console.log("Login Failed");
    },
    flow: "auth-code",
    scope: "profile email",
  });
  const promptConsent = useGoogleLogin({
    onSuccess: (codeResponse) => {
      successresponseGoogle(codeResponse);
    },
    onError: () => {
      console.log("Login Failed");
    },
    flow: "auth-code",
    scope: "profile email https://www.googleapis.com/auth/drive.file",
    prompt: "consent",
  });

  let navigate = useNavigate();

  useEffect(() => {
    let accountLogRef;
    let aToken = token;
    firebaseUser = firebase2.auth().currentUser;

    const isGoogleUser =
      firebaseUser &&
      firebaseUser.providerData.some(
        (profile) => profile.providerId === "google.com"
      );

    if (aToken && isGoogleUser) {
      // useRefresh(aToken);
    }

    const unsubscribe = firebase2
      .auth()
      .onAuthStateChanged(function (firebaseUser1) {
        firebaseUser = firebase2.auth().currentUser;
        let isPasswordProvider = false;

        if (firebaseUser) {
          firebaseUser.providerData.forEach(function (profile) {
            if (profile.providerId === "password" && isGoogle === false) {
              isPasswordProvider = true;
            }
          });
        }
        if (isPasswordProvider) {
          // if the current user authorized with email (firebase) exit this function
          return;
        }
        if (firebaseUser) {
          var apiServer = "";
          apiServer = "https://us-central1-webinarstvus.cloudfunctions.net/";

          if (
            firebaseUser &&
            typeof userData === "object" &&
            Object?.keys(userData).length < 1 &&
            firebaseUser.ya
          ) {
            dispatch(setFirebaseToken(firebaseUser.ya));
            handleFirebaseIDTokenChange(firebaseUser.ya);
            let counter = 0;
            const getUserDataWebinarTv = function (token) {
              var headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              };
              fetch(apiServer + "getUserDataWebinarTv", {
                method: "POST",
                headers: headers,
              })
                .then((res) => {
                  console.log("preresponse0", res);
                  return res.text().then((text) => {
                    try {
                      return JSON.parse(text);
                    } catch (error) {
                      console.warn(
                        "Response is not valid JSON, returning as text:",
                        text
                      );
                      return null;
                    }
                  });
                })
                .then((jsonResponse) => {
                  console.log("jsonResponse", jsonResponse);

                  if (
                    jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.uid
                  ) {
                    let data = {
                      userId: jsonResponse?.data?.uid,
                      channelName: jsonResponse?.channelName,
                      description: jsonResponse?.description,
                      userHostId: jsonResponse?.data?.hosts
                        ? jsonResponse.data.hosts[0]
                        : jsonResponse.data?.userHostId,
                      subscription: jsonResponse?.data?.subscription,
                      expressUrl: jsonResponse?.data?.expressUrl,
                      email: jsonResponse?.data?.email,
                      searchEngine: jsonResponse?.data?.searchEngine,
                      showOnWebinarTv: jsonResponse?.data?.showOnWebinarTv,
                      submitToGoogle: jsonResponse?.data?.submitToGoogle,
                      webinarTvAutoRecord:
                        jsonResponse?.data?.webinarTvAutoRecord,
                      enabledChannel: jsonResponse?.enabledChannel,
                      enabledRecord: jsonResponse?.enabledRecord,
                      enabledSearchEngine: jsonResponse?.enabledSearchEngine,
                      enabledWebinarTv: jsonResponse?.enabledWebinarTv,
                      tier: jsonResponse?.tier,
                      category: jsonResponse?.category,
                      subcategory: jsonResponse?.subcategory,
                      other: jsonResponse?.data?.customSubcategory,
                      bannerImage: jsonResponse?.data?.hostImage,
                      profileImage: jsonResponse?.data?.profileImage,
                      agreedToTerms: jsonResponse?.data?.agreedToTerms,
                      name:
                        jsonResponse?.data?.name ||
                        jsonResponse?.data?.displayName,
                      enabledCategories: jsonResponse?.data?.enabledCategories,
                      enabledRecord: jsonResponse?.data?.enabledRecord,
                      enabledSearchEngine:
                        jsonResponse?.data?.enabledSearchEngine,
                      enabledWebinarTv: jsonResponse?.data?.enabledWebinarTv,
                      hostImage: jsonResponse?.data?.hostImage,
                      titleCompany: jsonResponse.data?.titleCompany,
                      bio: jsonResponse.data?.bio,
                    };
                    if (tempFirebaseIDToken !== undefined) {
                      localStorage.setItem("userData", JSON.stringify(data));
                      dispatch(
                        setUserData({
                          ...userData,
                          ...jsonResponse.data,
                          ...data,
                        })
                      );

                      dispatch(setIsAuthLoading(false));
                      dispatch(setIsLoggedIn(true));
                      if (
                        typeof window !== "undefined" &&
                        window.localStorage
                      ) {
                        localStorage.removeItem("logout");
                      }
                      setAllDataReceived(true);
                      handleCloseCircular();
                      dispatch(
                        setOpenSuccess({
                          open: true,
                          message: "Log in success!",
                        })
                      );
                    }
                    if (jsonResponse.data?.hosts) {
                      dispatch(
                        setAccountPath(
                          `ws/account/${
                            jsonResponse.data?.hosts[0] || userData.userHostId
                          }`
                        )
                      );
                    } else {
                      dispatch(setAccountPath(`ws/account/nohost`));
                    }
                    profile = { ...profile, ...jsonResponse.data };
                    handleUserChange({ ...profile });
                    setLibraryNotif(
                      (jsonResponse?.seen &&
                        Object.keys(jsonResponse?.seen).length()) ||
                        0
                    );
                  } else if (jsonResponse === null) {
                    var userDataBody = {
                      email: firebaseUser.email,
                      uid: firebaseUser.uid,
                      name: `${firebaseUser.displayName}`,
                    };

                    var apiServer = "";

                    apiServer =
                      "https://us-central1-webinarstvus.cloudfunctions.net/";
                    var headers = {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    };
                    fetch(apiServer + "saveUserDataWebinars", {
                      method: "POST",
                      body: JSON.stringify(userDataBody),
                      headers: headers,
                    });
                    dispatch(setIsLoggedIn(true));

                    dispatch(setFirebaseToken(token));
                    handleSetUserSuccChange(true);
                    dispatch(
                      setUserData({
                        userId: firebaseUser.uid,
                        email: firebaseUser.email,
                        name: firebaseUser.displayName,
                        emailVerified: true,
                      })
                    );
                  } else {
                    if (counter < 10) {
                      counter++;
                      setTimeout(() => {
                        if (firebaseUser && firebaseUser.ya) {
                          getUserDataWebinarTv(firebaseUser?.ya);
                          counter = 10;
                        }
                      }, 1000);
                    }
                  }
                })
                .catch((error) => {
                  console.log("getusererr", error);
                  handleCloseCircular();
                  sendErrorReport(
                    error,
                    `Google Login | ${
                      error?.message ? error.message : error.toString()
                    }`,
                    userData
                  );
                });
            };
            getUserDataWebinarTv(firebaseUser?.ya);
          }
        }
        if (firebaseUser && !gotHist) {
          // console.log("gotHist", gotHist);
          gotHist = true;
          accountLogRef = firebase2
            .database()
            .ref(`/hostCapture/${firebaseUser.uid}/`);

          accountLogRef.on("value", function (snapshot) {
            var acl = snapshot.val();
            tempSched = acl;

            setSched(tempSched);
            histPopulator();
          });
        }
      });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (accountLogRef) {
        accountLogRef.off("value");
      }
    };
  }, [refreshFinished]);

  function histPopulator() {
    if (tempHist && tempSched) {
      Object.keys(tempHist).forEach((element, index, array) => {
        if (
          Object.values(tempHist[element])[0] &&
          Object.values(tempHist[element])[0].captureLocation
        ) {
          tempHist[element] = {
            ...tempSched[
              Object.values(tempHist[element])[0].captureLocation.split("/")[1]
            ][element],
          };
        }
      });
      setHist({ ...tempHist });
    }
  }
  const logOut = () => {
    if (pathname.includes("account") || pathname.includes("settings")) {
      navigate("/");
    } else {
      navigate(pathname);
    }
    googleLogout();
    onLogoutSuccess();
  };

  const onLogoutSuccess = () => {
    localStorage.setItem("logout", Date.now().toString());
    localStorage.setItem("accessTk", undefined);
    localStorage.setItem("idToken", undefined);
    localStorage.setItem("userData", {});
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.removeItem("subscriptionData");
    }
    localStorage.setItem("firebaseIDToken", undefined);
    setIsGoogle(false);

    dispatch(resetWebinarState());
    dispatch(resetUserState());
    dispatch(resetWebinarState());
    handleFirebaseIDTokenChange(undefined);
    setAllDataReceived(false);
    dispatch(setIsAuthLoading(true));
    dispatch(setIsLoggedIn(false));
    tempFirebaseIDToken = undefined;
    setRefreshFinished(false);
    handleUserChange(undefined);
    firebase2.auth().signOut();
    handleOpenSuccessLogout();
  };
  const goToPPLDashboard = () => {
    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var urlencoded = new URLSearchParams();
    urlencoded.append("access_token", token);

    fetch("https://ads01.webinartv.us/advertiser/api/adserver/login", {
      method: "POST",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    })
      .then((res) => {
        return res.json();
      })
      .then((jsonResponse) => {
        console.log("jsonResponse", jsonResponse);
        window.open(`${jsonResponse.redirect_url}`, "rel=noopener noreferrer");
      })
      .catch((error) => {
        alert(`error: ${JSON.stringify(error)}`);
      });
  };

  // useEffect(() => {
  //   let isComponentMounted = true;

  //   const unsubscribe = firebase2.auth().onAuthStateChanged(function (firebaseUser) {
  //     let isPasswordProvider = false;

  //     if (firebaseUser) {
  //       firebaseUser.providerData.forEach(function (profile) {
  //         if (profile.providerId === 'password' && isGoogle === false) {
  //           isPasswordProvider = true;
  //           return;
  //         }
  //       });
  //     }
  //     if (isPasswordProvider) {
  //       // if the current user authorized with email (firebase) exit this function
  //       return;
  //     }
  //     if (firebaseUser && isComponentMounted) {
  //       firebaseUser.getIdToken().then(function (idToken) {
  //         let authToken = idToken;
  //         localStorage.setItem("firebaseIDToken", idToken);

  //         dispatch(setFirebaseToken(idToken));
  //         handleFirebaseIDTokenChange(idToken);

  //         // console.log("firebase2 gettoken");
  //         // console.log("firebase2 gettoken2", firebaseIDToken);

  //         if (firebaseUser) {
  //           console.log(
  //             "with credential2"
  //             // user,
  //             // firebaseUser,
  //             // {
  //             //   ...user,
  //             //   ...firebaseUser,
  //             // }
  //           );

  //           profile = { ...profile, ...firebaseUser };
  //           handleUserChange({ ...profile });
  //           if (profile) {
  //             dispatch(setUserData({
  //               userId: profile.uid,
  //               email: profile.email,
  //               name: profile.name || profile.displayName,
  //               emailVerified: profile.emailVerified,
  //             }));
  //           }
  //           dispatch(setIsLoggedIn(true));
  //           handleCloseFireSignup();
  //         }

  //         var userDataBody = {
  //           ...userData,
  //           email: firebaseUser.email,
  //           access_token: authToken,
  //         };
  //         var apiServer = "";
  //         dispatch(setFirebaseToken(idToken));
  //         let tempToken = idToken;

  //         apiServer = "https://us-central1-webinarstvus.cloudfunctions.net/";
  //         var headers = {
  //           Authorization: `Bearer ${tempToken}`,
  //           "Content-Type": "application/json",
  //         };
  //         var itt = 0;
  //         function waitForElement() {
  //           if (authToken) {
  //             // console.log("saveuserdatabody", userDataBody);

  //             fetch(apiServer + "saveUserDataWebinars", {
  //               method: "POST",
  //               body: JSON.stringify(userDataBody),
  //               headers: headers,
  //             })
  //               .then((res) => {
  //                 // console.log("SaveUserData", res, res.body);
  //                 handleSetUserSuccChange(true);
  //               })

  //               .catch((err) => {
  //                 console.log(err);
  //                 sendErrorReport(err, "user login: no webinar id", userData);
  //               });
  //           } else {
  //             itt++;
  //             console.log(
  //               "no IdToken"
  //               // IdTokenHolder,
  //               // IdToken,
  //               // userDataBody,

  //               // userDataBody.refresh_Token.refresh_token
  //             );
  //             if (itt < 150) {
  //               setTimeout(waitForElement, 500);
  //             } else {
  //               let message = `Google Sign in timed out! Contact support@MeetingTV.us with this message:
  //               ${JSON.stringify(IdTokenHolder)}
  //               -
  //               ${JSON.stringify(credential)}`;
  //               dispatch(setOpenSuccess({ open: true, message: message }));
  //             }
  //           }
  //         }
  //         waitForElement();
  //       });
  //     }
  //   });

  //   return () => {
  //     isComponentMounted = false;
  //     unsubscribe();
  //   }
  // }, [refreshFinished]);

  const firebaseSignup = async (data) => {
    handleCloseLogin();
    handleOpenCircular();

    let lowerCaseEmail = data?.email.toLowerCase();
    try {
      const currentUser = firebase2.auth().currentUser;

      const userCredential = await firebase2
        .auth()
        .createUserWithEmailAndPassword(lowerCaseEmail, data.loginPassword);
      let user = userCredential.user;

      const getRandomNumber = () => {
        return Math.floor(Math.random() * (2000000 - 100000 + 1)) + 100000;
      };
      const randomNumber = getRandomNumber();
      const tempName = `MeetingTV user${randomNumber}`;

      if (user) {
        await user.updateProfile({
          displayName: tempName,
        });

        let token = await user.getIdToken();

        var userDataBody = {
          email: lowerCaseEmail,
          uid: user.uid,
          name: `${tempName}`,
        };

        var apiServer = "";

        apiServer = "https://us-central1-webinarstvus.cloudfunctions.net/";
        var headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        if (token) {
          try {
            if (token) {
              await fetch(apiServer + "saveUserDataWebinars", {
                method: "POST",
                body: JSON.stringify(userDataBody),
                headers: headers,
              });
              dispatch(setIsLoggedIn(true));

              dispatch(setFirebaseToken(token));
              handleSetUserSuccChange(true);
              dispatch(
                setUserData({
                  userId: user.uid,
                  email: lowerCaseEmail,
                  name: user.displayName,
                  emailVerified: user.emailVerified,
                })
              );
            }
            if (isNewVisitor) {
              // createWebinar
              let data = { ...dummyWebinarData };
              const url =
                "https://us-central1-webinarstvus.cloudfunctions.net/createEvent";
              try {
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                };
                handleOpenCircular();
                let response = await axios.post(url, dummyWebinarData, config);
                // clearTimeout(timeoutId);
                if (response && response.data) {
                  const id = response.data[0].id;
                  data.id = id;
                  handleFirstTimeWalkthrough();
                  dispatch(setWebinarEvent(data));
                  dispatch(setActiveEventTab(2));
                  navigate(
                    `./ws/account/${user.uid}/manage-event/${data.id}/customize`
                  );
                }
              } catch (error) {
                // clearTimeout(timeoutId);
                handleCloseCircular();
                if (error && error.response && error.response.data) {
                  if (error.response?.status === 400) {
                    if (
                      error.response?.data ===
                      "event time Overlaps with existing event"
                    ) {
                      dispatch(
                        setChannelOffMessage(
                          "The time you've selected overlaps with one of your existing webinars. Please choose a new time for this webinar."
                        )
                      );
                      dispatch(setChannelOffSuccess(true));
                    } else {
                      dispatch(
                        setChannelOffMessage(
                          "There was an error creating this webinar. Please make sure all fields are filled out."
                        )
                      );
                      dispatch(setChannelOffSuccess(true));
                    }
                    dispatch(
                      setChannelOffMessage(
                        "There was an issue creating your webinar. Please refresh the window and try again."
                      )
                    );
                    dispatch(setChannelOffSuccess(true));
                  }
                }
                console.log(`Error creating webinar: ${error}`);
                error.formValues = data;
                await sendErrorReport(
                  error,
                  `FirebaseSignup Api: saveUserDataWebinars | ${
                    error?.message ? error.message : error.toString()
                  }`,
                  userData
                );
              }
            }
          } catch (error) {
            console.log(error);
            sendErrorReport(
              error,
              `saveUserData | ${
                error?.message ? error.message : error.toString()
              }`,
              userData
            );
          }
        }

        await user.sendEmailVerification();

        handleFirebaseIDTokenChange(token);
        dispatch(setAccountPath(`ws/account/${user.uid}`));
      }
    } catch (error) {
      console.log("Error in user creation:", error);
      await sendErrorReport(
        error,
        `firebaseSignup | ${error?.message ? error.message : error.toString()}`,
        userData
      );
    } finally {
      handleCloseCircular();
    }
  };

  const firebaseLogin = async (email) => {
    let localIsInvalid = false;
    var idToken;
    reactGA.event({
      label: "Firebase2 Login Initiated",
      category: "Login",
      action: "Firebase2 Login Initiated",
    });
    handleOpenCircular();
    let lowerCaseEmail = email?.email.toLowerCase();
    try {
      let user = await firebase2
        .auth()
        .signInWithEmailAndPassword(lowerCaseEmail, email?.loginPassword);

      let userName = user.user.displayName;
      if (token) {
        let parsedJWT = JSON.parse(
          Buffer.from(token.split(".")[1], "base64").toString()
        );
        exp = parsedJWT.exp * 1000;
      }

      if (!token || Date.now() >= exp) {
        idToken = await user.user.getIdToken();

        dispatch(setFirebaseToken(idToken));
        handleCloseFireSignup();
        handleFirebaseIDTokenChange(idToken);
        tempFirebaseIDToken = idToken;
      } else {
        idToken = token;
      }
      let apiServer = "https://us-central1-webinarstvus.cloudfunctions.net/";

      var headers = {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      };
      await fetch(apiServer + "getUserDataWebinarTv", {
        method: "POST",
        headers: headers,
      })
        .then((res) => {
          return res.json();
        })
        .then((jsonResponse) => {
          reactGA.event({
            label: "Firebase2 Login Success",
            category: "Login",
            action: "Firebase2 Login Success",
          });

          // if (jsonResponse) {
          //   if (!jsonResponse.data || !jsonResponse.data.uid) {
          //     const getRandomNumber = () => {
          //       return (
          //         Math.floor(Math.random() * (2000000 - 100000 + 1)) + 100000
          //       );
          //     };

          //     const randomNumber = getRandomNumber();
          //     const tempName = `MeetingTV user${randomNumber}`;
          //     var userDataBody = {
          //       email: email.email,
          //       name: `${tempName}`,
          //       uid: user.user.uid,
          //     };
          //     var apiServerSave = "";

          //     apiServerSave =
          //       "https://us-central1-webinarstvus.cloudfunctions.net/";
          //     var headers = {
          //       Authorization: `Bearer ${idToken}`,
          //       "Content-Type": "application/json",
          //     };
          //     const resetUserData = async () => {
          //       try {
          //         if (idToken) {
          //           await fetch(apiServerSave + "saveUserDataWebinars", {
          //             method: "POST",
          //             body: JSON.stringify(userDataBody),
          //             headers: headers,
          //           });
          //           handleSetUserSuccChange(true);
          //         }
          //       } catch (error) {
          //         console.log(error);
          //         sendErrorReport(
          //           error,
          //           `resetUserData | ${error?.message ? error.message : error.toString()
          //           }`,
          //           userData
          //         );
          //       }
          //     };
          //     resetUserData();
          //     const emailInfo = {
          //       email: email.email,
          //       loginPassword: email.loginPassword,
          //     };
          //     firebaseLogin(emailInfo);
          //     jsonResponse["data"]["uid"] = user.user.uid;
          //     jsonResponse["data"]["displayName"] = tempName;
          //     jsonResponse["data"]["email"] = email.email;
          //   }
          // }

          let data = {
            userId: jsonResponse.data?.uid,
            channelName: jsonResponse?.channelName,
            description: jsonResponse?.description,
            userHostId: jsonResponse.data?.hosts
              ? jsonResponse?.data?.hosts[0]
              : jsonResponse.data?.userHostId,
            subscription: jsonResponse.data?.subscription,
            expressUrl: jsonResponse?.data?.expressUrl,
            email: jsonResponse.data?.email,
            searchEngine: jsonResponse.data?.searchEngine,
            showOnWebinarTv: jsonResponse.data?.showOnWebinarTv,
            submitToGoogle: jsonResponse.data?.submitToGoogle,
            webinarTvAutoRecord: jsonResponse.data?.webinarTvAutoRecord,
            enabledChannel: jsonResponse.enabledChannel,
            enabledRecord: jsonResponse.enabledRecord,
            enabledSearchEngine: jsonResponse.enabledSearchEngine,
            enabledWebinarTv: jsonResponse.enabledWebinarTv,
            tier: jsonResponse.tier,
            category: jsonResponse?.category,
            subcategory: jsonResponse?.subcategory,
            other: jsonResponse?.data?.customSubcategory,
            bannerImage: jsonResponse.data?.hostImage,
            profileImage: jsonResponse.data?.profileImage,
            agreedToTerms: jsonResponse.data?.agreedToTerms,
            name: jsonResponse.data?.name || jsonResponse.data?.displayName,
            hostImage: jsonResponse.data?.hostImage,
            titleCompany: jsonResponse.data?.titleCompany,
            bio: jsonResponse.data?.bio,
          };

          if (idToken !== undefined) {
            localStorage.setItem("userData", JSON.stringify(data));
            dispatch(setUserData({ ...jsonResponse.data, ...data }));
            dispatch(setIsAuthLoading(false));
            dispatch(setIsLoggedIn(true));
            setAllDataReceived(true);
            handleCloseCircular();
          }
          if (jsonResponse.data?.hosts) {
            dispatch(setAccountPath(`ws/account/${userData.userId}`));
          } else {
            dispatch(setAccountPath(`ws/account/${jsonResponse.data?.uid}`));
          }
          profile = { ...profile, ...jsonResponse.data };
          handleUserChange({ ...profile });
          setLibraryNotif(
            (jsonResponse?.seen && Object.keys(jsonResponse?.seen).length()) ||
              0
          );
        })
        .catch((error) => {
          console.log("getusererr", error);
          handleCloseCircular();
          sendErrorReport(
            error,
            `getUserDataWebinarTv | ${
              error?.message ? error.message : error.toString()
            }`,
            userData
          );
        });
      return idToken;
    } catch (error) {
      handleCloseCircular();
      console.log(`Error authenticating firebase2 user: ${error.message}`);
      let message = error.message.split(".")[0];
      if (error.message.includes("corresponding")) {
        dispatch(
          setChannelOffMessage(
            `Are you a new user? If so, sign up to start creating webinars for free!`
          )
        );
        dispatch(setChannelOffSuccess(true));
      } else if (error.message.includes("invalid")) {
        localIsInvalid = true;
        setIsInvalid(true);
      } else {
        dispatch(setChannelOffMessage(error.message));
        dispatch(setChannelOffSuccess(true));
      }
      throw new Error(error);
    } finally {
      if (!localIsInvalid) {
        handleCloseLogin();
      }
    }
  };

  const handleLoginOrSignup = async (data) => {
    let lowerCaseEmail = data?.email.toLowerCase();

    try {
      const signInMethods = await firebase2
        .auth()
        .fetchSignInMethodsForEmail(lowerCaseEmail);
      if (signInMethods.length > 0) {
        if (signInMethods.includes("google.com")) {
          dispatch(
            setChannelOffMessage(
              `This account was created using Google. Please click "Log in with Google" to log in.`
            )
          );
          dispatch(setChannelOffSuccess(true));
          alert(
            'This account was created using Google. Please click "Log in with Google" to log in.'
          );
          return;
        }
        if (signInMethods.includes("password")) {
          await firebaseLogin(data);
        }
      } else {
        await firebaseSignup(data);
      }
    } catch (error) {
      console.error("Error in user authentication:", error);
    }
  };

  var menuItems = inAccountPage
    ? [
        {
          text: "Create Webinar",
          icon: AddIcon,
          pathName: `ws/account/${
            userData.userHostId ?? hostIdWhenClaimed
          }/viewer-history`,
          host: userData.userHostId ? true : false,
          altPath: `ws/account/nohost/viewer-history`,
        },
        {
          text: "Content",
          icon: Content,
          pathName: `ws/account/${
            userData.userHostId ?? hostIdWhenClaimed
          }/content`,
          host: userData.userHostId ? true : false,
          altPath: `ws/account/nohost/content`,
        },
        {
          text: "Analytics",
          icon: Analytics,
          pathName: `ws/account/${
            userData.userHostId ?? hostIdWhenClaimed
          }/analytics`,
          host: userData.userHostId ? true : false,
          altPath: `ws/account/nohost/analytics`,
        },
        {
          text: "Customize",
          icon: Customize,
          pathName: `ws/account/${
            userData.userHostId ?? hostIdWhenClaimed
          }/customize/details`,
          host: userData.userHostId ? true : false,
          altPath: `ws/account/nohost/customize/details`,
        },
        {
          text: "Settings",
          icon: Setting,
          pathName: `ws/account/${
            userData.userHostId || hostIdWhenClaimed || userData.userId
          }/settings`,
          host: true,
        },
      ]
    : [
        { text: "Home", icon: Home, pathName: "/", host: true },
        {
          text: "Library",
          icon: Content,
          pathName: "ws/library",
          notif: hist ? Object.keys(hist).length - libraryNotif * 1 : 0,
          host: true,
        },
        {
          text: "Categories",
          icon: ListAlt,
          pathName: "ws/categories1",
          host: true,
        },
        {
          text: "Subscriptions",
          icon: RecentActorsIcon,
          pathName: "ws/subscriptions",
          host: true,
        },
        {
          text: "Recommended",
          icon: Recommended,
          pathName: "ws/recommended",
          host: true,
        },
      ];

  const responseGoogle = (response) => {
    console.log(response);
  };

  const successresponseGoogle = (response) => {
    reactGA.event({
      label: "Login Success",
      category: "Login",
      action: "Login Success!",
    });

    if (response.code) {
      var apiServer = "";

      apiServer = "https://us-central1-zoocorder.cloudfunctions.net/";
      var headers = {
        "Content-Type": "text/plain",
      };

      var codeBod = {
        client_id: clientID,
        client_secret: clientSecret,
        redirect_uri: "postmessage",
        grant_type: "authorization_code",
        code: response.code,
      };

      console.log("grefreshbod", codeBod);
      fetch(apiServer + "gRefresh", {
        method: "POST",
        body: JSON.stringify(codeBod),
        headers: headers,
      })
        .then((res) => {
          return res.json();
        })
        .then((preResponse) => {
          if (preResponse.access_token && preResponse.id_token) {
            localStorage.setItem("accessTk", preResponse.access_token);
            localStorage.setItem("idToken", preResponse.id_token);

            useRefresh(preResponse.id_token);
            handleCloseCircular();
          }
        });
    } else {
      var tempUser = response.profileObj;
      profile = { ...profile, ...tempUser };
      handleUserChange({ ...profile });
      handleUserIDChange(user.googleId);
      handleCloseCircular();
    }
  };

  const useRefresh = (id_Token) => {
    console.log("userefreshtrigger", id_Token);
    var credential = provider.credential(id_Token);

    firebase2
      .auth()
      .signInWithCredential(credential)
      .then((credential) => {
        setRefreshFinished(true);
        console.log("signInWith credential", credential, credential.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (pathname.includes("account")) {
      dispatch(setInAccountPage(true));
    } else {
      dispatch(setInAccountPage(false));
    }
  }, [pathname]);

  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  let isMobilePortrait = window.mobileCheck();

  const screenSmall = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (screenSmall) {
      setOpen(false);
    }
    if (!screenSmall) {
      setOpen(true);
    }
  }, [screenSmall]);

  function tokenChecker() {
    console.log("triggerTokenChecker");

    if (token && firebase2.auth().currentUser) {
      if (!tokenCheck) {
        settokenCheck(true);
        console.log("tokenchecktrue", token);
        if(typeof token === 'string' || token instanceof String){
        let parsedJWT = JSON.parse(
          Buffer.from(token.split(".")[1], "base64").toString()
        );
        exp = parsedJWT.exp * 1000;
      } else {
         exp = 0
        }
        if (exp < Date.now()) {
          console.log("token", token);
          setTimeout(function () {
            tokenUpdate();
            settokenCheck(false);
          }, exp - Date.now()); // Repeat every 60000 milliseconds (1 minute)
        } else {
          console.log("tokenStillValid");
        }
      }
    }
  }
  function tokenUpdate() {
    console.log("token Expired!", exp - Date.now());

    firebaseUser = firebase2.auth().currentUser;
    console.log("firebaseUser", firebaseUser);

    firebaseUser.getIdToken().then((tempToken) => {
      try {
        console.log("new token saved:", tempToken, token);

        if (tempToken && tempToken !== token) {

          dispatch(setFirebaseToken(tempToken));
          handleUserChange({
            ...profile,
            ...{ token: tempToken, access_token: tempToken },
          });
        }
      } catch (error) {
        console.log(error);
        sendErrorReport(
          error,
          `token refresh (Menu.js) | ${
            error?.message ? error.message : error.toString()
          }`,
          userData
        );
      }
    });
  }

  useEffect(() => {
    tokenChecker();
  }, [token, firebaseUser]);
  useEffect(() => {
    document.addEventListener("visibilitychange", visChngF);
    function visChngF() {
      if (document.hidden) {
        console.log("page unfocused");
      } else {
        console.log("page focused");
        tokenChecker();
      }
    }
  }, []);

  useEffect(() => {
    if (!webinarData || !webinarData[0]) {
      if (token) {
        console.log("library fetch ran");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const url = `https://us-central1-webinarstvus.cloudfunctions.net/getLibrary?status=host`;
        const getWebinars = async () => {
          try {
            let response = await axios.get(url, { headers: headers });
            dispatch(setAllEvents(response.data.data));
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log("Request canceled:", error.message);
            } else {
              console.log(`Error getting event data: ${error}`);
              await sendErrorReport(
                error,
                `getWebinars (Menu.js) | ${
                  error?.message ? error.message : error.toString()
                }`,
                userData
              );
            }
          }
        };
        getWebinars();
      }
    }
  }, [userData, token]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "logout") {
        localStorage.setItem("accessTk", undefined);
        localStorage.setItem("idToken", undefined);
        if (typeof window !== "undefined" && window.localStorage) {
          localStorage.removeItem("userData");
          localStorage.removeItem("subscriptionData");
          localStorage.setItem("firebaseIDToken", undefined);
        }
        dispatch(setFirebaseToken(""));
        dispatch(setUserData({}));
        handleFirebaseIDTokenChange(undefined);
        dispatch(setIsLoggedIn(false));
        dispatch(setIsAuthLoading(true));
        dispatch(setInAccountPage(false));
        setAllDataReceived(false);
        tempFirebaseIDToken = undefined;
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const isCancelPage = pathname.includes("/how-to-cancel");

  console.log("isLoggedIn", isLoggedIn);
  console.log("isAuth", isAuthLoading);

  return (
    <>
      <div> {<div className="menuSpacer"></div>}</div>
      <Box sx={{ display: "flex", width: "100%" }}>
        <CssBaseline />
        {displayNav && (
          <AppBar
            position="fixed"
            sx={{ paddingLeft: "0px", marginLeft: "0px" }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "flex-end",
                  alignItems: "center",
                  minWidth: "0px",
                  paddingTop: "0px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setInAccountPage(false));
                    navigate("/");
                  }}
                >
                  <div
                    onClick={() => {
                      dispatch(setInAccountPage(false));
                      navigate("/");
                    }}
                    className="headerButton"
                    style={{
                      display: "flex",
                      minWidth: "0px",
                      marginTop: "-4px",
                      flex: "1 4 auto",
                    }}
                  >
                    <div className="logoHolder">
                      <img
                        alt="webinar tv company logo"
                        src={companyLogo}
                        width="200px"
                        height="35.62px"
                      />
                      <p className="formerlyWTV">(WebinarTV)</p>
                    </div>
                  </div>
                </div>
                <div className="turtleNav">
                <p
                    className="navLink"
                    onClick={() => {
                        navigate(`/catalog`);
                    }}
                  >
                    Catalog
                  </p>
                  <p
                    className="navLink"
                    onClick={() => {
                      if (!userData.purchaseMade) {
                        navigate("/ppl");
                      } else {
                        goToPPLDashboard();
                      }
                    }}
                  >
                    Get Leads
                  </p>
                  <p
                    className="navLink"
                    onClick={() => {
                      if (userData && userData.userId) {
                        navigate(`/ws/account/${userData.userId}/create-event`);
                      } else {
                        navigate("/ws/account/create-event");
                      }
                    }}
                  >
                    Add Webinar
                  </p>


                  {/* <p className="navLink" onClick={() => {
                      if (userData && userData.userId) {
                        navigate(`/ws/account/${userData.userId}/create-meeting`);
                      } else {
                        navigate("/ws/account/create-meeting");
                      }
                    }}>
                      Create Meeting
                    </p> */}
                  <p
                    className="navLink"
                    onClick={() => {
                      if (userData && userData.userId) {
                        navigate(
                          webinarData?.length
                            ? `/ws/account/${userData.userId}/hosted`
                            : `/ws/account/${userData.userId}/viewer-history`
                        );
                      } else {
                        navigate("/ws/account/create-event");
                      }
                    }}
                  >
                    Library
                  </p>


                  <p
                    className="navLink"
                    onClick={() => {
                      navigate(`/ws/account/faq`);
                    }}
                  >
                    FAQ
                  </p>

                  <p className="navLink" onClick={() => navigate("/pricing")}>
                    Pricing
                  </p>
                  <p
                    className="navLink"
                    onClick={() => {
                      window.location.assign("https://forum.WebinarTV.us/");
                    }}
                  >
                    ?
                  </p>
                </div>

                <div className="turtleBurger">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      if (modalOpen) {
                        handlerFunction(setModalOpen2, true);
                      } else {
                        handlerFunction(setModalOpen2, false);
                      }
                    }}
                    edge="start"
                    sx={{
                      marginRight: 2,
                      paddingRight: "1vw",
                    }}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                </div>
                <div>
                  <WebinarCounter />
                </div>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {isLoggedIn ? (
                    <Box
                      sx={{
                        display: "flex",
                        flex: "1 3 auto",
                        justifyContent: "end",
                      }}
                    >
                      <AccountButton
                        setInAccountPage={setInAccountPage}
                        inAccountPage={inAccountPage}
                        userId={userId}
                        logOut={logOut}
                        userData={userData}
                      />
                    </Box>
                  ) : (
                    <IconButton
                      sx={{
                        fontSize: "calc(15px + 1vw)",
                        marginLeft: "calc(0px)",
                        marginBottom: "2px",
                      }}
                      id="loginButton"
                      aria-label="Log In"
                      onClick={() => {
                        dispatch(setIsAuthLoading(true));
                        handleOpenLogin();
                      }}
                    >
                      Log In
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        )}

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <div className="navButtons2">
              <p
                className="navLink"
                onClick={() => {
                  setModalOpen(false);
                  if (userData && userData.userId) {
                    navigate(`/ws/account/${userData.userId}/create-event`);
                  } else {
                    navigate("/ws/account/create-event");
                  }
                }}
              >
                Add Webinar
              </p>
              <Divider />

              <p
                className="navLink"
                onClick={() => {
                  setModalOpen(false);
                  if (userData && userData.userId) {
                    navigate(
                      webinarData
                        ? `/ws/account/${userData.userId}/hosted`
                        : `/ws/account/${userData.userId}/viewer-history`
                    );
                  } else {
                    navigate("/ws/account/create-event");
                  }
                }}
              >
                Library
              </p>
              <Divider />

              <p
                className="navLink"
                onClick={() => {
                  setModalOpen(false);
                  if (!userData.purchaseMade) {
                    navigate("/ppl");
                  } else {
                    goToPPLDashboard();
                  }
                }}
              >
                Get Leads
              </p>

              <Divider />

              <p
                className="navLink"
                onClick={() => {
                  setModalOpen(false);
                  navigate("/pricing");
                }}
              >
                Pricing
              </p>

              <Divider />
            </div>
          </Box>
        </Modal>

        <Modal
          open={modalOpen2}
          onClose={() => setModalOpen2(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <div className="navButtons2">
            <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                    navigate(`/catalog`);
                }}
              >
                Catalog
              </p>
              <Divider />
              <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                  if (!userData.purchaseMade) {
                    navigate("/ppl");
                  } else {
                    goToPPLDashboard();
                  }
                }}
              >
                Get Leads
              </p>
              <Divider />
              <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                  if (userData && userData.userId) {
                    navigate(`/ws/account/${userData.userId}/create-event`);
                  } else {
                    navigate("/ws/account/create-event");
                  }
                }}
              >
                Add Webinar
              </p>
              <Divider />


              <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                  if (userData && userData.userId) {
                    navigate(
                      webinarData
                        ? `/ws/account/${userData.userId}/hosted`
                        : `/ws/account/${userData.userId}/viewer-history`
                    );
                  } else {
                    navigate("/ws/account/create-event");
                  }
                }}
              >
                Library
              </p>
              <Divider />

              <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                  navigate("/ws/account/faq");
                }}
              >
                FAQ
              </p>
              <Divider />
              <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                  navigate("/pricing");
                }}
              >
                Pricing
              </p>
              <Divider />
              <p
                className="navLink"
                onClick={() => {
                  setModalOpen2(false);
                  window.location.assign("https://forum.WebinarTV.us/");
                }}
              >
                ?
              </p>
            </div>
          </Box>
        </Modal>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ display: "sticky" }}
          className="navButtons"
        >
          <DrawerHeader sx={{ marginTop: isMobilePortrait ? "32px" : "0px" }}>
            <IconButton onClick={() => handlerFunction(setOpen, open)}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item) => (
              <Link
                to={item.host ? item.pathName : item.altPath}
                key={item.text}
              >
                <ListItem
                  disablePadding
                  disabled={!item.host}
                  sx={{ display: "block", color: "#71797E" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.MuiButtonBase-root": {
                        paddingLeft: "20px !Important",
                        paddingRight: "20px !Important",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Badge
                        badgeContent={item.notif}
                        color="notif"
                        style={{ color: "white" }}
                      >
                        <Tooltip
                          title={item.text}
                          enterDelay={300}
                          enterNextDelay={300}
                          leaveDelay={200}
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 800 }}
                        >
                          <item.icon sx={{ color: "#71797E" }} />
                        </Tooltip>
                      </Badge>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, color: "#71797E" }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
          <List>
            {[{ text: "FAQ", icon: FAQ, pathName: "ws/account/faq" }].map(
              (item, index) => (
                <Link to={item.pathName} key={item.text}>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", color: "#71797E" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        "&.MuiButtonBase-root": {
                          paddingLeft: "20px !Important",
                          paddingRight: "20px !Important",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <Tooltip
                          title={item.text}
                          enterDelay={300}
                          enterNextDelay={300}
                          leaveDelay={200}
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 800 }}
                        >
                          <item.icon sx={{ color: "#71797E" }} />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0, color: "#71797E" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )
            )}
            <ListItem
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLScIWqGA8r9l25TXTrCTN43C0Y5qQZLBWnr0YGF1Hsj8E9MP5A/viewform"
                )
              }
              disablePadding
              sx={{ display: "block", color: "#71797E" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&.MuiButtonBase-root": {
                    paddingLeft: "20px !Important",
                    paddingRight: "20px !Important",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title="Created Channel"
                    enterDelay={1000}
                    enterNextDelay={1000}
                    leaveDelay={200}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 800 }}
                  >
                    <AddIcon sx={{ color: "#71797E" }} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="Create Channel"
                  sx={{ opacity: open ? 1 : 0, color: "#71797E" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLScIWqGA8r9l25TXTrCTN43C0Y5qQZLBWnr0YGF1Hsj8E9MP5A/viewform"
                )
              }
              disablePadding
              sx={{ display: "block", color: "#71797E" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&.MuiButtonBase-root": {
                    paddingLeft: "20px !Important",
                    paddingRight: "20px !Important",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title="Add Webinar"
                    enterDelay={1000}
                    enterNextDelay={1000}
                    leaveDelay={200}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 800 }}
                  >
                    <AddIcon sx={{ color: "#71797E" }} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="Add Webinar"
                  sx={{ opacity: open ? 1 : 0, color: "#71797E" }}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <DrawerHeader />
        </Drawer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: isCancelPage ? "0px" : "10px",
            width: "100%",
            overflow: "visible",
          }}
        >
          <AppRoutes
            token={token}
            setUserSucc={setUserSucc}
            logIn={logIn}
            promptConsent={promptConsent}
            user={user || userData}
            reactGA={reactGA}
            hist={hist}
            sched={sched}
            libraryNotif={libraryNotif}
            setLibraryNotif={setLibraryNotif}
            handleCloseCircular={handleCloseCircular}
            isMobilePortrait={isMobilePortrait}
            firebaseLogin={firebaseLogin}
            handleOpenCircular={handleOpenCircular}
            setOpenLogin={setOpenLogin}
            openLogin={openLogin}
            firebaseSignup={firebaseSignup}
            handleOpenFireSignup={handleOpenFireSignup}
            handleCloseLogin={handleCloseLogin}
            firebase2={firebase2}
            handleLoginOrSignup={handleLoginOrSignup}
            setDisplayNav={setDisplayNav}
          />
        </Box>
      </Box>
      <Modal
        open={openCircular}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="circular-modal-style">
          <CircularProgress />
        </Box>
      </Modal>
      {isAuthLoading && !isLoggedIn && (
        <Modal
          open={openLogin}
          onClose={handleCloseLogin}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <RequestModals
            sx={{ width: isMobilePortrait ? "86%" : "40%" }}
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleLoginOrSignup)}
          >
            <Box sx={formTitle}>
              {isRegisterPath ? (
                <p className="login-modal-title-text">
                  Log in/Sign up to register for this webinar.
                </p>
              ) : (
                <>
                  <p style={{ margin: 0, padding: 0, alignSelf: "flex-start" }}>
                    <b>Log in to create unlimited webinars for free.</b>
                  </p>
                  <p className="login-modal-title-text">
                    Creating a webinar, even a practice one, secures your spot
                    as a pioneer host, guaranteeing free access for life.{" "}
                    <b>Credit card not required because it's simply free.</b>
                  </p>
                </>
              )}
            </Box>
            {!hideGoogleLogin && (
              <div style={{ width: "100%" }}>
                <div className="google-login-container">
                  <button
                    aria-label="Log in with google"
                    type="button"
                    className="login-google-btn"
                    onClick={() => {
                      logIn();
                      handleCloseLogin();
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <img height="23px" width="32" src={googleLogin} />
                    </span>
                    <span>Log in with Google</span>
                  </button>
                </div>
                <p
                  style={{
                    fontSize: "20px",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "0.1em",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    marginTop: "20px",
                    marginBottom: 0,
                  }}
                >
                  <span style={{ background: "#fff", padding: "0 10px" }}>
                    — OR —
                  </span>
                </p>
              </div>
            )}
            <div className="or-sign-up-email">
              <p className="or-sign-up-text">
                Log in/Sign up with your work or personal email
              </p>
            </div>
            {isInvalid && (
              <div className="incorrect-password-box">
                <span>
                  <b>Incorrect password</b>. Please try again or you can&nbsp;
                  <Link
                    style={{ color: "white" }}
                    to="/ws/password-reset-request"
                    onClick={() => handleCloseLogin()}
                  >
                    <u>reset your password</u>.
                  </Link>
                </span>
              </div>
            )}
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  onChange={field.onChange}
                  value={field.value}
                  sx={{ width: "100% !Important", marginTop: "0px !important" }}
                  variant="standard"
                  autoComplete="off"
                />
              )}
            />

            <Controller
              name="loginPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onBlur={() => {
                    field.onBlur();
                    trigger("loginPassword");
                  }}
                  error={touchedFields.loginPassword && !!error}
                  helperText={
                    touchedFields.loginPassword && error ? error.message : ""
                  }
                  sx={{
                    width: "100% !Important",
                    border: "none",
                    "input[type=text]:focus": { outline: "none !Important" },
                  }}
                  variant="standard"
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {touchedFields.loginPassword && errors.loginPassword && (
              <p style={{ color: "red", margin: "0 0 0 5px" }}>
                {errors.loginPassword?.message}
              </p>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <button
                aria-label="Login with email"
                className="ripple-button ripple-login"
                variant="contained"
                color="primary"
                type="submit"
              >
                Log In
              </button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "0px !important",
              }}
            >
              <p
                style={{
                  cursor: "pointer",
                  margin: "0px",
                }}
                onClick={() => {
                  handleCloseLogin();
                  navigate("/ws/password-reset-request");
                }}
              >
                <u>Reset password</u>
              </p>
            </Box>
          </RequestModals>
        </Modal>
      )}
      <SnackbarAlert />
      <CustomAlertChannelStatus />
    </>
  );
};

export default Menu;
