import { useState, useEffect } from "react";
import { Box, Typography, Modal, Link } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setChannelOffSuccess } from "./redux/slices/channelOffSuccessSlice";
import { setOpenSuccess } from "./redux/slices/conditionalUiSlice";
import { handleResendClick } from "./Menu.js";

const alertContainer = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
  boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
};

const CustomAlertChannelStatus = ({ id }) => {
  const [parsedMessage, setParsedMessage] = useState([]);
  const dispatch = useDispatch();
  const message = useSelector((state) => state.channel.channelOffMessage);
  const open = useSelector((state) => state.channel.isChannelOffSuccess);
  const webinarData = useSelector((state) => state.event.registerWebinarEvent);
  const webinarDataChannel = useSelector(
    (state) => state.webinar.mainWebinarData
  );
  const userData = useSelector((state) => state.user.userData);
  const channelWebinarId = webinarDataChannel?.webinarId;
  const webinarId = webinarData?.webinarId;
  const navigate = useNavigate();

  const handleClose = () => dispatch(setChannelOffSuccess(false));

  const parseMessage = (message) => {
    const effectiveId = id || webinarId || channelWebinarId || "default";
    const parts = [];
    const linkMarkers = [
      {
        marker: "[ENTER_THE_STUDIO]",
        replaceWith: "Enter the Studio",
        url: `https://MeetingTV.us/join?MID=${effectiveId}`,
      },
      {
        marker: "[CHANNEL_PAGE]",
        replaceWith: "Channel Page",
        url: "https://MeetingTV.us/settings/channel",
      },
      {
        marker: "[LIBRARY]",
        replaceWith: "Library",
        url: `https://MeetingTV.us/ws/account/${userData?.userId}/hosted`,
      },
      {
        marker: "[Resend Verification Email]",
        replaceWith: "Resend Verification Email",
        url: "",
      },
      {
        marker: "[JOIN_LINK]",
        replaceWith: "here",
        url: `https://MeetingTV.us/join?MID=${effectiveId}`,
      },
    ];

    let lastIndex = 0;

    linkMarkers.forEach((link) => {
      const startMarker = link.marker;
      let indexStart = message.indexOf(startMarker);

      while (indexStart !== -1) {
        if (indexStart > lastIndex) {
          parts.push({
            type: "text",
            content: message.substring(lastIndex, indexStart),
          });
        }
        parts.push({ type: "link", content: link.replaceWith, url: link.url });
        lastIndex = indexStart + startMarker.length;
        indexStart = message.indexOf(startMarker, lastIndex);
      }
    });

    if (lastIndex < message.length) {
      parts.push({ type: "text", content: message.substring(lastIndex) });
    }

    if (parts.length === 0) {
      parts.push({ type: "text", content: message });
    }

    return parts;
  };

  useEffect(() => {
    setParsedMessage(parseMessage(message));
    return () => {
      setParsedMessage([]);
    };
  }, [message]);

  useEffect(() => {
    if (id === undefined) {
    }
  }, [id]);

  return (
    <>
      {id && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={alertContainer}>
            <div className="modal-header">
              <Typography id="modal-modal-title">Notice</Typography>
              <button className="close-button" onClick={handleClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "20px", color: "#5d676b" }}
              >
                {parsedMessage.map((part, index) => {
                  if (part.type === "link") {
                    if (part.content === "Resend Verification Email") {
                      return (
                        <Link
                          key={index}
                          onClick={() => {
                            handleResendClick();
                            handleClose();
                            dispatch(
                              setOpenSuccess({
                                open: true,
                                message: "Verification Email Sent!",
                              })
                            );
                          }}
                          sx={{
                            cursor: "pointer",
                            color: "#1976d2",
                            verticalAlign: "baseline",
                          }}
                        >
                          {part.content}
                        </Link>
                      );
                    }
                    return (
                      <Link
                        key={index}
                        onClick={() => {
                          handleClose();
                          window.open(part.url, "_blank");
                        }}
                        sx={{
                          cursor: "pointer",
                          color: "#1976d2",
                          verticalAlign: "baseline",
                        }}
                      >
                        {part.content}
                      </Link>
                    );
                  }
                  return <span key={index}>{part.content}</span>;
                })}
              </Typography>
            </div>
            <div className="modal-footer">
              <button className="custom-button" onClick={handleClose}>
                Close
              </button>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CustomAlertChannelStatus;
