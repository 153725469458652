import { createSlice } from "@reduxjs/toolkit";

const adBlockerDetectionSlice = createSlice({
  name: "detection",
  initialState: {
    adBlocker: false,
  },
  reducers: {
    setAdBlockerDetected: (state, action) => {
      state.adBlocker = action.payload
    }
  }
});

export const { setAdBlockerDetected } = adBlockerDetectionSlice.actions;
export default adBlockerDetectionSlice.reducer;