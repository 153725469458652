import { useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSuccess } from "./redux/slices/conditionalUiSlice";
import { StyledAlertSnackbar } from "./styles/snackbarStyle";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const SnackbarAlert = () => {
  const { open, message } = useSelector(
    (state) => state.conditionalUi.openSuccess
  ) || { open: false, message: "" };

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setOpenSuccess({ open: false, message: "" }));
  };

  return (
    <StyledAlertSnackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      message={
        <span className="snack-icon-message">
          <CheckCircleOutlineIcon className="icon" /> {message}
        </span>
      }
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    />
  );
};

export default SnackbarAlert;
