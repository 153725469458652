import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meetings: [],
  meeting: {},
  filteredMeetings: [],
};

const meetingsSlice = createSlice({
  name: "meetingData",
  initialState,
  reducers: {
    setMeetings(state, action) {
      state.meetings = action.payload;
    },
    setMeeting(state, action) {
      state.meeting = action.payload;
    },
    setFilteredMeetings: (state, action) => {
      state.filteredMeetings = action.payload;
    },
  },
});

export const {
  setMeetings,
  setFilteredMeetings,
  setMeeting
} = meetingsSlice.actions;

export default meetingsSlice.reducer;