import React, { useState, useRef } from "react";
import { InstantSearch, Hits, SearchBox, connectStateResults } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
//import algoliasearch from 'https://cdn.jsdelivr.net/npm/algoliasearch@4/dist/algoliasearch-lite.esm.browser.js';

// or just use algoliasearch if you are using a <script> tag
// if you are using AMD module loader, algoliasearch will not be defined in window,
// but in the AMD modules of the page
const algoliaClient = algoliasearch(
  "4Z3J4NQCAW",
  "a8012b4a2fb767c5bc178d80d327712e"
);
const index = algoliaClient.initIndex("hostwebinars");
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    } else {
      return algoliaClient.search(requests);
    }
  },
};

function HitComponent(props, { customStateResults: CustomStateResults }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currentTime = Date.now();
  let webinarStatus;
  console.log(props.hit)
  if (
    currentTime > props.hit.meetingStartTime &&
    currentTime < props.hit.meetingStartTime + 3600000 &&
    props.hit.over == false
  ) {
    webinarStatus = "live";
  } else if (
    currentTime < props.hit.meetingStartTime &&
    props.hit.over === false
  ) {
    webinarStatus = "upcoming";
  } else if (currentTime > props.hit.meetingStartTime + 3600000) {
    webinarStatus = "past";
  } else if (props.over === true || props?.checkOver === true) {
    webinarStatus = "past";
  }

  return (
    <div>
      <div>
        {!props.searchState.query.length > 5 ?
          (
            <CustomStateResults />
          )
          :
          (<>
            <p
              style={{ cursor: "pointer" }}
              class="hitp"
              onClick={() => {
                props.setSearchState({
                  ...props.searchState,
                  query: "",
                });
                navigate(`${props.hit.hostId}/${props.hit.objectID || props.hit.id}`)
              }}
            >
              {props.hit.topic}
            </p>
            <p class="hitp">{" " + props.hit.time}</p>
          </>)
        }
      </div>
    </div>
  );
}

const Search = () => {
  const [searchState, setSearchState] = useState({});
  const [searchResults, setSearchResults] = ([]);

  const StateResults = ({ searchResults, searchState }) => {
    const noResults = searchState?.query?.length && !searchResults?.hits?.length;

    return (
      <div
        style={{
          marginTop: "-14px",
          whiteSpace: "nowrap"
        }}
      >
        {noResults ?
          <p>No results found for "{searchState?.query}"</p>
          :
          <div
            style={{
              marginTop: "-14px",
              width: "20px",
              height: "14px"
            }}
          >
          </div>}
      </div>
    );
  };

  const CustomStateResults = connectStateResults(StateResults);

  return (
    <div className="ais-InstantSearch" style={{ width: "100%", maxWidth: "390px" }}>
      <InstantSearch
        indexName="hostwebinars"
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={setSearchState}
      >
        <div className="right-panel">
          <SearchBox />
          <div style={{}}>
            <CustomStateResults />

          </div>
          <Hits
            hitComponent={({ hit }) => (
              <HitComponent
                hit={hit}
                searchState={searchState}
                setSearchState={setSearchState}
                searchResults={searchResults}
                customStateResults={CustomStateResults}
              />
            )}
          >
          </Hits>
        </div>
      </InstantSearch>
      <br></br>
    </div>
  );
};

export default Search;
