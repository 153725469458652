import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChannelOffSuccess: false,
  channelOffMessage: "",
};

const channelOffSuccessSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    setChannelOffSuccess(state, action) {
      state.isChannelOffSuccess = action.payload;
    },
    setChannelOffMessage(state, action) {
      state.channelOffMessage = action.payload;
    },

  },
});

export const { setChannelOffSuccess, setChannelOffMessage } = channelOffSuccessSlice.actions;

export default channelOffSuccessSlice.reducer;