export const isJsonObject = (strData) => {
  try {
    JSON.parse(strData);
  } catch (e) {
    return false;
  }
  return true;
};

const webinarApi = {
  getHost: async (id, token) => {
    let parameter;

    parameter = `https://us-central1-webinarstvus.cloudfunctions.net/getHostArchiveWeb?id=${id}`;

    let result = await fetch(parameter, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },

  getUser: async (token) => {
    let parameter;

    parameter = `https://us-central1-webinarstvus.cloudfunctions.net/getUserDataWebinarTv`;

    let result = await fetch(parameter, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },

  getWebinar: async (id, token) => {
    let parameter;

    parameter = `https://us-central1-webinarstvus.cloudfunctions.net/getShowId?id=${id}`;

    let result = await fetch(parameter, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },

  getWebinars: async () => {
    const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/getOnNow?id=-N8xjX1IDcspPUtY-rTC`;

    let result = await fetch(endPoint, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("GETWEBINARID", data);
        return data;
      });
    return result;
  },

  getTrending: async () => {
    const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/getInitialData`;

    let result = await fetch(endPoint, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },

  getStorage: async () => {
    let result = await fetch(
      "https://storage.googleapis.com/webinartv-200-webinars/webinars-200.json"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((preResponse) => {
        return preResponse;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    return result;
  },

  sendContactEmail: async (data, token) => {
    let strData = JSON.stringify(data);
    const endPoint =
      "https://us-central1-webinarstvus.cloudfunctions.net/contactHost";

    let result = await fetch(endPoint, {
      method: "POST",
      body: strData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
        return error.message;
      });
    return result;
  },

  saveHostData: async (data, token) => {
    let strData = JSON.stringify(data);
    const endPoint =
      "https://us-central1-webinarstvus.cloudfunctions.net/saveHostData";

    let result = await fetch(endPoint, {
      method: "POST",
      body: strData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (isJsonObject(response)) {
          console.log(response);
          return response.json();
        } else {
          return response.text();
        }
        return response.text();
      })
      .then((jsonResponse) => {
        return jsonResponse;
      })
      .catch((error) => {
        return error;
      });
    return result;
  },

  postData: async (data, api, token, fileType) => {
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/${api}`;

    let response = await fetch(url, {
      method: "POST",
      body: strData,
      headers: {
        filetype: fileType,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  },

  setPromotePreference: async (data, promoteOption, token) => {
    let strData = JSON.stringify(data);
    await fetch(
      "https://us-central1-webinarstvus.cloudfunctions.net/saveUserDataWebinars",
      {
        method: "PATCH",
        body: strData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  updateWebinar: async (data, api, token) => {
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/${api}`;

    await fetch(url, {
      method: "POST",
      body: strData,
      headers: {
        filetype: fileType,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  unsubscribe: async (data, token) => {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/unsubscribe`;

    await fetch(url, {
      method: "POST",
      body: strData,
      config,
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          return response.text();
        }
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getMoreWebinars: async (categoryType, docId) => {
    const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/getShowCategories?size=20&sort=Trending&category=${categoryType}&startAfterDoc=${docId}`;

    let result = await fetch(endPoint, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },
  getShowCategories: async () => {
    // const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/getShowCategories?size=200&sort=Trending`;

    // let result = await fetch(endPoint, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "text/plain",
    //   },
    // })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     return data;
    //   });
    // return result;
  },

  addRecordingLogToHost: async (data) => {
    // let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/addRecordingLogToHost`;

    await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getHostsUsersRecordingLogs: async (hostId) => {
    const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/getHostsUsersRecordingLogs?hostId=${hostId}`;

    let result = await fetch(endPoint, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },

  deleteData: async (data, api) => {
    const strData = JSON.stringify(data);

    const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/${api}`;

    await fetch(endPoint, {
      method: "DELETE",
      body: strData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  addSubscriber: async (data) => {
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/addSubscriber`;

    await fetch(url, {
      method: "POST",
      body: strData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getHostSubscribers: async (hostId) => {
    const endPoint = `https://us-central1-webinarstvus.cloudfunctions.net/getHostSubscribers?hostId=${hostId}`;

    let result = await fetch(endPoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    return result;
  },

  disableHostAccount: async (data) => {
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/disableHostAccount`;

    await fetch(url, {
      method: "POST",
      body: strData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  editWebinar: async (data, hostId, meetingStartTime) => {
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/editWebinar?hostId=${hostId}&meetingStartTime=${meetingStartTime}`;

    let response = await fetch(url, {
      method: "POST",
      body: strData,
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  },

  sendClaimChannelEmail: async (data) => {
    let strData = JSON.stringify(data);
    const url = `https://us-central1-webinarstvus.cloudfunctions.net/sendClaimChannel`;

    let response = await fetch(url, {
      method: "POST",
      body: strData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (isJsonObject(response)) {
            console.log(response);
            return response.json();
          }
        } else {
          throw Error(response);
        }
        return response.text();
      })
      .then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  },

  passwordResetRequest: async (data) => {
    const strData = JSON.stringify(data);
    const url =
      "https://us-central1-webinarstvus.cloudfunctions.net/passwordReset";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: strData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          return jsonResponse;
        } else {
          const textResponse = await response.text();
          console.log(textResponse);
          return textResponse;
        }
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error during password reset request:", error);
      throw error;
    }
    return response;
  },
};

export default webinarApi;
