import { createSlice } from "@reduxjs/toolkit";

const conditionalUiSlice = createSlice({
  name: "conditionalUi",
  initialState: {
    activeEventTab: 1,
    activeAnalyticTab: 1,
    displayScheduler: true,
    openSuccess: {
      open: false,
      message: "",
    },
    firstTimeWalkthrough: false,
    webinarSortType: "recentlyUpdated",
    fromCreate: false,
    isDataLoading: false,
    newUserMessage: {
      open: false,
      message: "",
    },
    newUserMsg: "",
    newUserOpen: false,
    openLogin: false,
    activeLibraryTab: 1,
  },
  reducers: {
    setActiveEventTab(state, action) {
      state.activeEventTab = action.payload;
    },
    setOpenSuccess(state, action) {
      const { open, message } = action.payload;
      if (state.openSuccess) {
        state.openSuccess.open = open;
        state.openSuccess.message = message;
      }
    },
    setDisplayScheduler(state, action) {
      const newValue = !state.displayScheduler;
      state.displayScheduler =
        action.payload !== undefined ? action.payload : newValue;
    },
    setFirstTimeWalkthrough(state, action) {
      state.firstTimeWalkthrough = action.payload;
    },
    setActiveAnalyticTab(state, action) {
      state.activeAnalyticTab = action.payload;
    },
    setWebinarSortType(state, action) {
      state.webinarSortType = action.payload;
    },
    setFromCreate(state, action) {
      state.fromCreate = action.payload;
    },
    setIsDataLoading(state, action) {
      state.isDataLoading = action.payload;
    },
    setNewUserMessage(state, action) {
      state.newUserMsg = action.payload;
    },
    setNewUserOpen(state, action) {
      state.newUserOpen = action.payload;
    },
    setOpenLogin(state, action) {
      state.openLogin = action.payload;
    },
    setActiveLibraryTab(state, action) {
      state.activeLibraryTab = action.payload;
    },
  },
});

export const {
  setActiveEventTab,
  setDisplayScheduler,
  setOpenSuccess,
  setFirstTimeWalkthrough,
  setActiveAnalyticTab,
  setWebinarSortType,
  setFromCreate,
  setIsDataLoading,
  setNewUserMessage,
  setNewUserOpen,
  setOpenLogin,
  setActiveLibraryTab,
} = conditionalUiSlice.actions;

export default conditionalUiSlice.reducer;
