import { createSlice } from "@reduxjs/toolkit";

const userHostDataSlice = createSlice({
  name: "userHostData",
  initialState: {
    userHostData: {},
    hostIdWhenClaimed: "",
  },
  reducers: {
    setUserHostData: (state, action) => {
      state.userHostData = action.payload
    },
    updateUserHostDataField: (state, action) => {
      const { field, value } = action.payload;
      state.userHostData[field] = value;
    },
    setHostIdWhenClaimed: (state, action) => {
      state.hostIdWhenClaimed = action.payload;
    },
  }
});

export const { setUserHostData, updateUserHostDataField, setHostIdWhenClaimed } = userHostDataSlice.actions;
export default userHostDataSlice.reducer;