import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { sendErrorReport } from "../../utils/sendError";

export const fetchCategories = createAsyncThunk('categories/fetch', async () => {
  const url = "https://us-central1-webinarstvus.cloudfunctions.net/getCategories";
  try {
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error);
    } else {
      console.log(`Error fetching categories: ${error}`);
      sendErrorReport(error, "fetch categories", "func: categoriesFetch")
    }
    return {
      "categories": [
        "Business",
        "Education",
        "Entertainment",
        "Health/Fitness",
        "Medical",
        "Personal Finance",
        "Politics",
        "Technology"
      ],
      "subcategories": {
        "Business": [
          "Advertising",
          "Business Development",
          "Business Strategy",
          "Consumer Analysis",
          "Consumer Engagement",
          "Content Marketing",
          "Crowdfunding",
          "Customer Experience",
          "Data Analytics",
          "Digital Advertising",
          "E-commerce",
          "Email Marketing",
          "Entrepreneurship",
          "Franchise",
          "Fundraising",
          "Human Resources",
          "Influencer Marketing",
          "Intellectual Property",
          "Investing",
          "Lead Generation",
          "Legal",
          "LinkedIn",
          "Market Analysis",
          "Marketing",
          "Outsourcing",
          "Passive Income",
          "Product Strategy",
          "Project Management",
          "Public Relations",
          "Risk Management",
          "Sales",
          "SEO",
          "Social Media Marketing",
          "Startups",
          "Trading",
          "Venture Capital",
          "Webinars",
          "Other"
        ],
        "Medical": [
          "Allergies",
          "Anti-Aging",
          "Anxiety",
          "Arthritis",
          "Asthma",
          "Autoimmune Diseases",
          "Birth Control",
          "Cholesterol",
          "Chronic Conditions",
          "Cosmetic Procedures",
          "Dementia",
          "Depression",
          "Dermatology",
          "Diabetes",
          "Digestive Health",
          "Diseases",
          "Erectile Dysfunction",
          "General Health",
          "Geriatrics",
          "Hair Loss",
          "Hepatitis",
          "High Blood Pressure",
          "HIV/AIDS",
          "IBS",
          "Infertility",
          "Menopause",
          "Mental Health",
          "Migraine",
          "Nutrition",
          "Obesity",
          "Oncology",
          "Opioid Pain Relievers",
          "Organ Health",
          "Over-the-Counter Solutions",
          "Pain Management",
          "Pediatrics",
          "Rare Diseases",
          "Reproductive Health",
          "Skin",
          "Vaccination",
          "Weight Management",
          "Other"
        ],
        "Education": [
          "Admissions Consulting",
          "Art",
          "Assessment",
          "Blended Learning",
          "Careers",
          "Charter Schools",
          "Coaching",
          "College Prep",
          "Continuing Education",
          "Curriculum Creation",
          "DEI",
          "EdTech",
          "Education Research",
          "Education Software",
          "eLearning",
          "Grant Writing",
          "Homeschooling",
          "Instructional Design",
          "Leadership",
          "Learning Games",
          "Literacy",
          "Magnet Schools",
          "Methods",
          "Online Courses",
          "Private Schools",
          "Programs",
          "Reading",
          "Reform",
          "Remote Learning",
          "Scholarships",
          "School Choice",
          "Sexual Health",
          "Skills",
          "Special Needs",
          "Standards",
          "STEM",
          "Student Engagement",
          "Study Skills",
          "Teacher Tools",
          "Technology",
          "Test Prep",
          "Training",
          "University",
          "Vouchers",
          "Writing",
          "Other"
        ],
        "Entertainment": [
          "Acting",
          "Animation",
          "Art",
          "Beauty",
          "Books",
          "Celebrity",
          "Choreography",
          "Comedy",
          "Concerts",
          "Cooking",
          "Costumes",
          "Crafting",
          "Creative Writing",
          "Dance",
          "Design",
          "Digital Art",
          "DJ Sessions",
          "Documentary",
          "eSports",
          "Fashion",
          "Film Making",
          "Gaming",
          "Gardening",
          "Independent Films",
          "Improv",
          "Literature",
          "Magic",
          "Movie Reviews",
          "Music",
          "Pets",
          "Poetry",
          "Podcasts",
          "Production",
          "Reviews",
          "Science",
          "Screenwriting",
          "Sports",
          "Storytelling",
          "Street Performances",
          "Theater",
          "Tournaments",
          "Tours",
          "Trivia",
          "Video Editing",
          "Virtual Reality",
          "Vocal Training",
          "Voice Acting",
          "Travel",
          "Walkthroughs",
          "Writing",
          "Other"
        ],
        "Health/Fitness": [
          "Aerobics",
          "Barre",
          "Bodyweight Exercises",
          "Boot Camp Workouts",
          "Boxing",
          "Calisthenics",
          "Cardiovascular Fitness",
          "Cleanse Routines",
          "Core Training",
          "CrossFit",
          "Cycling",
          "Dance",
          "Diet",
          "Emerging Fitness",
          "Fitness Apps",
          "Fitness Business Strategies",
          "Flexibility",
          "Foam Rolling",
          "Functional Fitness",
          "HIIT",
          "Holistic Skin & Beauty",
          "Home Gym Equipment",
          "Home Workouts",
          "Marathon Training",
          "Martial Arts",
          "Meditation",
          "Natural Remedies",
          "Nutrition",
          "Outdoor Recreation",
          "Pilates",
          "Plant-Based Diets",
          "Prenatal Fitness",
          "Resistance Bands",
          "Running",
          "Senior Wellness",
          "Sexual Health",
          "Sleep",
          "Soccer",
          "Sports Nutrition",
          "Strength Training",
          "Stretching",
          "Swimming",
          "Tennis",
          "Virtual Reality Fitness",
          "Wearable Fitness Tech",
          "Weight Loss",
          "Weightlifting",
          "Yoga",
          "Youth Fitness",
          "Other"
        ],
        "Politics": [
          "Activism",
          "Campaign Fundraising",
          "Campaign Strategies",
          "Civil Liberties",
          "Conservative",
          "Constituency Services",
          "Current Events",
          "Democrat",
          "Democratic Socialist",
          "Economic Policy",
          "Election Law",
          "Electoral Politics",
          "Environmental Policy",
          "Executive Branch",
          "Foreign Policy",
          "Grassroots",
          "Green",
          "Human Rights",
          "Immigration Insights",
          "Independant",
          "International Diplomacy",
          "Judicial Affairs",
          "Leadership",
          "Legislative Updates",
          "Libertarian",
          "Media Influence",
          "Military and Defense",
          "Moderate",
          "Nationalist",
          "Neoconservative",
          "Neoliberal",
          "Opinion and Commentary",
          "Political Ethics",
          "Political Movements",
          "Populist",
          "Progressive",
          "Public Policy Analysis",
          "Republican",
          "Social Justice",
          "State and Local",
          "Tea Party",
          "Voter Outreach",
          "Other"
        ],
        "Technology": [
          "5G and Connectivity",
          "Artificial Intelligence (AI)",
          "Augmented Reality (AR)",
          "Big Data and Analytics",
          "Biotechnology",
          "Blockchain Technology",
          "ChatGPT",
          "Cloud Computing",
          "Consumer Electronics Reviews",
          "Cybersecurity",
          "Data Science",
          "DevOps",
          "Digital Marketing",
          "Digital Transformation",
          "Edge Computing",
          "Gaming Technology",
          "Internet of Things (IoT)",
          "IT Compliance",
          "Large Language Models",
          "Mobile Development",
          "Network and Infrastructure",
          "Open Source",
          "Quantum Computing",
          "Robotics",
          "Software Development",
          "Tech Ethics and Policy",
          "Tech Startups",
          "UI/UX Design",
          "User Data Privacy",
          "Video Meetings",
          "Virtual Reality (VR)",
          "Web Development",
          "Other"
        ],
        "Personal Finance": [
          "Budgeting Tips",
          "Building Wealth",
          "Charter Schools",
          "College Financing",
          "Credit Card Rewards",
          "Credit Repair",
          "Debt Relief",
          "DEI",
          "Early Retirement",
          "Estate Planning",
          "Financial Independence",
          "Financial Planning",
          "Home Buying",
          "Identity Theft Protection",
          "Insurance",
          "Investment Strategies",
          "Lowering Bills",
          "Making Money from Home",
          "Mortgages",
          "Passive Income",
          "Personal Finance Management",
          "Private Schools",
          "Protecting Your Assets",
          "Real Estate Investing",
          "Retirement Planning",
          "Saving Money",
          "School Choice",
          "Side Hustles",
          "Small Business Finance",
          "Software Tools",
          "Stock Market Analysis",
          "Tax Planning",
          "Trading",
          "Other",
        ]
      }
    };
  }
});

const initialState = {
  categories: [],
  status: 'idle',
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default categoriesSlice.reducer;