import React, { useEffect, useState, lazy, Suspense } from "react";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import moment from "moment-timezone";
import countdown from "countdown";
import CircularProgress from "@mui/material/CircularProgress";
import InlinePlayer from "./inlinePlayer";

var sched;
var rows = [];

var apiServer = "https://us-central1-webinarstvus.cloudfunctions.net/";

console.log("localemu", process.env);
if (process.env.REACT_APP_LOCAL_EMULATOR == "1" && false) {
  console.log("localemu");
  var apiServer = "http://localhost:5001/webinarstvus/us-central1/";
}

var headers = {
  "Content-Type": "text/plain",
};
var startAt;
var currentEnd;
var timeOffset;
var loadingPageRef;
var chartData;

// function createData(id, popularity, topic, date, category, e, f, g, h, i) {
//   return {
//     id,
//     popularity,
//     topic,
//     date,
//     category,
//     e,
//     f,
//     g,
//     h,
//     i,
//   };
// }

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    "& .MuiPaper-root": {
      padding: 0,
    },
  },
}));

export default function Chart(props) {
  const [loadingPage, setLoadingPage] = useState(loadingPageRef);
  loadingPageRef = loadingPage;

  function handleScroll() {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      !loadingPageRef &&
      currentEnd !== startAt
    ) {
      console.log("you're at the bottom of the page");

      if (currentEnd !== startAt) {
        console.log("getpage trigger");
        setLoadingPage(true);
        currentEnd = startAt;
        // getPage();
      } else {
        console.log("nopage", currentEnd, startAt, loadingPageRef);
      }
      // Show loading spinner and make fetch request to api
    } else {
      console.log(
        "loadingscroll",
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100,
        !loadingPageRef,
        currentEnd !== startAt
      );
    }
  }

  const classes = useStyles();

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
  }, []);
  if (window.location.pathname === "/chart") {
    useEffect(() => {
      setLoadingPage(true);

      var temptimeFrameOffset = Date.now();

      var url;
      let urlBase = "getOnNow";
      let pastTempString = "past=" + props.timeFrame + "&";

      url = apiServer + urlBase + `?size=120&notOver=true`;
      console.log(url);

      fetch(
        url,

        {
          method: "GET",
          headers: headers,
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((preResponse) => {
          console.log("preresponse", preResponse);
          var tempChartData = { ...chartData };
          // console.log(tempChartData, props.chartID, props.timeFrame);
          tempChartData[props.chartID] = {};
          tempChartData[props.chartID][props.timeFrame] = {
            ...preResponse.docs,
          };
          if (
            !props.chartData ||
            !props.chartData[props.chartID] ||
            !props.chartData[props.chartID][props.timeFrame]
          ) {
            // console.log("rerender", props.chartData);
            props.setChartData(tempChartData);
          }
          chartData = tempChartData;

          // console.log("tempChartdata1", tempChartData, preResponse.docs);

          var cdHolder =
            tempChartData[Object.keys(tempChartData)[0]][props.timeFrame];

          if (
            cdHolder[Object.keys(cdHolder)[Object.keys(cdHolder).length - 1]]
          ) {
            startAt =
              cdHolder[Object.keys(cdHolder)[Object.keys(cdHolder).length - 1]]
                .score;
            currentEnd = 0;
            timeOffset =
              cdHolder[Object.keys(cdHolder)[Object.keys(cdHolder).length - 1]]
                .meetingStartTime;
          } else {
            console.log("empty");
          }
          console.log("loadingpagefalse");
          setLoadingPage(false);
        });
      currentEnd = 0;
    }, [props.chartID, props.timeFrame]);
  }

  return (
    <React.Fragment>
      <div className="webinarTable">
        {props.chartData &&
          props.chartData[props.chartID] &&
          props.chartData[props.chartID][props.timeFrame] &&
          Object.keys(props.chartData[props.chartID][props.timeFrame]) &&
          props.chartData[props.chartID][props.timeFrame][
            Object.keys(props.chartData[props.chartID][props.timeFrame])
          ] && (
            <InlinePlayer
              url={
                props.chartData[props.chartID][props.timeFrame][
                  Object.keys(
                    props.chartData[props.chartID][props.timeFrame]
                  )[0]
                ].url
              }
            />
          )}
        <Divider />
        <Table size="small">
          <TableBody>
            {console.log("chartData", props.chartData)}
            {props.chartData[props.chartID] &&
              props.chartData[props.chartID][props.timeFrame] &&
              ((props.timeFrame === "day" &&
                Object.keys(props.chartData[props.chartID][props.timeFrame])
                  .sort((a, b) => {
                    if (
                      props.chartData[props.chartID][props.timeFrame][a]
                        .meetingStartTime >
                      props.chartData[props.chartID][props.timeFrame][b]
                        .meetingStartTime
                    ) {
                      return 1;
                    }
                    if (
                      props.chartData[props.chartID][props.timeFrame][b]
                        .meetingStartTime >
                      props.chartData[props.chartID][props.timeFrame][a]
                        .meetingStartTime
                    ) {
                      return -1;
                    }
                    if (
                      props.chartData[props.chartID][props.timeFrame][a].score <
                      props.chartData[props.chartID][props.timeFrame][b].score
                    ) {
                      return 1;
                    }
                    if (
                      props.chartData[props.chartID][props.timeFrame][b].score <
                      props.chartData[props.chartID][props.timeFrame][a].score
                    ) {
                      return -1;
                    }

                    return 0;
                  })
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div className="inline">
                          <div className="numLabelHolder">
                            <p className="numLabel">
                              {props.chartData[props.chartID][props.timeFrame][
                                row
                              ].joinUrl &&
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].joinUrl.includes("tk=") &&
                                "("}
                              {index + 1}
                              {props.chartData[props.chartID][props.timeFrame][
                                row
                              ].joinUrl &&
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].joinUrl.includes("pwd=") &&
                                "."}
                            </p>
                          </div>
                          <div className="textHolder2">
                            <img
                              className="logoImage"
                              src={
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ] &&
                                (props.chartData[props.chartID][
                                  props.timeFrame
                                ][row].customImage ||
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].graphic)
                              }
                            />
                          </div>{" "}
                          <div className="textHolder">
                            <a
                              href={`/${
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].hostId
                              }`}
                            >
                              {props.chartData[props.chartID][props.timeFrame][
                                row
                              ] &&
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].topic}
                            </a>
                          </div>{" "}
                        </div>
                      </TableCell>
                      <TableCell>
                        {(props.chartData[props.chartID][props.timeFrame][row]
                          .meetingStartTime && (
                          <div className="textHolder2">
                            {(Date.now() + 86400000 >
                              new Date(
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].meetingStartTime
                              ) && (
                              <div>
                                {(Date.now() <
                                  new Date(
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].meetingStartTime
                                  ) &&
                                  countdown(
                                    Date.now(),
                                    new Date(
                                      props.chartData[props.chartID][
                                        props.timeFrame
                                      ][row].meetingStartTime
                                    ),

                                    countdown.DEFAULTS,
                                    1
                                  ).toString()) ||
                                  `Live Now (${countdown(
                                    Date.now(),
                                    new Date(
                                      props.chartData[props.chartID][
                                        props.timeFrame
                                      ][row].meetingStartTime
                                    ),

                                    countdown.DEFAULTS,
                                    1
                                  ).toString()}) ago`}
                              </div>
                            )) ||
                              (props.chartData[props.chartID][props.timeFrame][
                                row
                              ].meetingStartTime && (
                                <div className="textHolder">
                                  {" "}
                                  {moment
                                    .tz(
                                      new Date(
                                        props.chartData[props.chartID][
                                          props.timeFrame
                                        ][row].meetingStartTime
                                      ),
                                      moment.tz.guess()
                                    )
                                    .format("LT") || 0}{" "}
                                  {moment
                                    .tz(
                                      new Date(
                                        props.chartData[props.chartID][
                                          props.timeFrame
                                        ][row].meetingStartTime
                                      ),
                                      moment.tz.guess()
                                    )
                                    .format("l") || 0}{" "}
                                  &nbsp;{" "}
                                  {false &&
                                    moment
                                      .tz(
                                        props.chartData[props.chartID][
                                          props.timeFrame
                                        ][row].time &&
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time,
                                        moment.tz.guess()
                                      )
                                      .format("z") !== "Invalid date" &&
                                    moment
                                      .tz(
                                        props.chartData[props.chartID][
                                          props.timeFrame
                                        ][row].time &&
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time,
                                        moment.tz.guess()
                                      )
                                      .format("z")}{" "}
                                </div>
                              )) || (
                                <div className="textHolder">
                                  {" "}
                                  {moment
                                    .tz(
                                      Date.parse(
                                        `${
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time &&
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time.split(" in ")[0]
                                        }`
                                      ),
                                      moment.tz.guess()
                                    )
                                    .format("LT") || 0}{" "}
                                  {moment
                                    .tz(
                                      Date.parse(
                                        `${
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time &&
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time.split(" in ")[0]
                                        }`
                                      ),
                                      moment.tz.guess()
                                    )
                                    .format("l") || 0}{" "}
                                  (Local Time)
                                  {false &&
                                    moment
                                      .tz(
                                        props.chartData[props.chartID][
                                          props.timeFrame
                                        ][row].time &&
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time,
                                        moment.tz.guess()
                                      )
                                      .format("z") !== "Invalid date" &&
                                    moment
                                      .tz(
                                        props.chartData[props.chartID][
                                          props.timeFrame
                                        ][row].time &&
                                          props.chartData[props.chartID][
                                            props.timeFrame
                                          ][row].time,
                                        moment.tz.guess()
                                      )
                                      .format("z")}{" "}
                                </div>
                              )}
                          </div>
                        )) || (
                          <div className="textHolder2">
                            {(Date.now() <
                              Date.parse(
                                `${
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time &&
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time.split(" in ")[0]
                                }`
                              ) &&
                              countdown(
                                Date.now(),

                                Date.parse(
                                  `${
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time.split(" in ")[0]
                                  }`
                                ),

                                countdown.DEFAULTS,
                                1
                              ).toString()) ||
                              `Live Now (${countdown(
                                Date.now(),

                                Date.parse(
                                  `${
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time.split(" in ")[0]
                                  } ago`
                                ),

                                countdown.DEFAULTS,
                                1
                              ).toString()})`}
                          </div>
                        )}
                      </TableCell>{" "}
                      <TableCell>
                        <div className="textholder">
                          {" "}
                          {props.chartData[props.chartID][row] &&
                            props.chartData[props.chartID][row].category}{" "}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))) ||
                Object.keys(props.chartData[props.chartID][props.timeFrame])
                  .sort((a, b) => {
                    if (
                      props.chartData[props.chartID][props.timeFrame][a].score <
                      props.chartData[props.chartID][props.timeFrame][b].score
                    ) {
                      return 1;
                    }
                    if (
                      props.chartData[props.chartID][props.timeFrame][b].score <
                      props.chartData[props.chartID][props.timeFrame][a].score
                    ) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div className="inline">
                          <div className="numLabelHolder">
                            <p className="numLabel">{index + 1}</p>{" "}
                          </div>
                          <div className="textHolder2">
                            <img
                              className="logoImage"
                              src={
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ] &&
                                (props.chartData[props.chartID][
                                  props.timeFrame
                                ][row].customImage ||
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].graphic)
                              }
                            />
                          </div>{" "}
                          <div className="textHolder">
                            <a
                              href={`/${
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].hostId
                              }`}
                            >
                              {props.chartData[props.chartID][props.timeFrame][
                                row
                              ] &&
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].topic}
                            </a>
                          </div>{" "}
                        </div>
                      </TableCell>

                      <TableCell>
                        {(props.chartData[props.chartID][props.timeFrame][row]
                          .meetingStartTime && (
                          <div className="textHolder2">
                            {(Date.now() <
                              new Date(
                                props.chartData[props.chartID][props.timeFrame][
                                  row
                                ].meetingStartTime
                              ) &&
                              countdown(
                                Date.now(),
                                new Date(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].meetingStartTime
                                ),

                                countdown.DEFAULTS,
                                1
                              ).toString()) ||
                              `Live Now (${countdown(
                                Date.now(),
                                new Date(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].meetingStartTime
                                ),

                                countdown.DEFAULTS,
                                1
                              ).toString()}) ago`}
                          </div>
                        )) || (
                          <div className="textHolder2">
                            {(Date.now() <
                              Date.parse(
                                `${
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time &&
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time.split(" in ")[0]
                                }`
                              ) &&
                              countdown(
                                Date.now(),

                                Date.parse(
                                  `${
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time.split(" in ")[0]
                                  }`
                                ),

                                countdown.DEFAULTS,
                                1
                              ).toString()) ||
                              `Live Now (${countdown(
                                Date.now(),

                                Date.parse(
                                  `${
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time.split(" in ")[0]
                                  } ago`
                                ),

                                countdown.DEFAULTS,
                                1
                              ).toString()})`}
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {(props.chartData[props.chartID][props.timeFrame][row]
                          .meetingStartTime && (
                          <div className="textHolder">
                            {" "}
                            {moment
                              .tz(
                                new Date(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].meetingStartTime
                                ),
                                moment.tz.guess()
                              )
                              .format("LT") || 0}{" "}
                            {moment
                              .tz(
                                new Date(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].meetingStartTime
                                ),
                                moment.tz.guess()
                              )
                              .format("l") || 0}{" "}
                            &nbsp;{" "}
                            {false &&
                              moment
                                .tz(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time,
                                  moment.tz.guess()
                                )
                                .format("z") !== "Invalid date" &&
                              moment
                                .tz(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time,
                                  moment.tz.guess()
                                )
                                .format("z")}{" "}
                          </div>
                        )) || (
                          <div className="textHolder">
                            {" "}
                            {moment
                              .tz(
                                Date.parse(
                                  `${
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time.split(" in ")[0]
                                  }`
                                ),
                                moment.tz.guess()
                              )
                              .format("LT") || 0}{" "}
                            {moment
                              .tz(
                                Date.parse(
                                  `${
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time.split(" in ")[0]
                                  }`
                                ),
                                moment.tz.guess()
                              )
                              .format("l") || 0}{" "}
                            (Local Time)
                            {false &&
                              moment
                                .tz(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time,
                                  moment.tz.guess()
                                )
                                .format("z") !== "Invalid date" &&
                              moment
                                .tz(
                                  props.chartData[props.chartID][
                                    props.timeFrame
                                  ][row].time &&
                                    props.chartData[props.chartID][
                                      props.timeFrame
                                    ][row].time,
                                  moment.tz.guess()
                                )
                                .format("z")}{" "}
                          </div>
                        )}
                      </TableCell>

                      <TableCell>
                        <div className="textholder">
                          {" "}
                          {props.chartData[props.chartID][row] &&
                            props.chartData[props.chartID][row].category}{" "}
                        </div>
                      </TableCell>
                    </TableRow>
                  )))}
          </TableBody>
        </Table>
        {/* {currentEnd !== startAt && (
          <div className="loading" id="loading">
            {loadingPage && (
              <div className="loadingPositioner">
                <CircularProgress />
              </div>
            )}
          </div>
        )} */}
      </div>

      <br />
    </React.Fragment>
  );
}
