import { createSlice } from "@reduxjs/toolkit";

const dummyWebinarSlice = createSlice({
  name: "dummyWebinar",
  initialState: {
    dummyWebinarData: {},
  },
  reducers: {
    setDummyWebinarData: (state, action) => {
      state.dummyWebinarData = action.payload;
    },
  },
});

export const { setDummyWebinarData } = dummyWebinarSlice.actions;

export default dummyWebinarSlice.reducer;