import React from "react";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    "& .MuiPaper-root": {
      padding: 0,
    },
  },
}));

export default function Categories(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className="CategoryHolder">
        <Divider />

        <ul>
          <li>
            <a href="/Webinars/Business/">Business</a>
          </li>
          <li>
            <a href="/Webinars/Education/">Education</a>
          </li>
          <li>
            <a href="/Webinars/Entertainment/">Entertainment</a>
          </li>
          <li>
            <a href="/Webinars/Health/">Health</a>
          </li>
          <li>
            <a href="/Webinars/Lifestyle/">Lifestyle</a>
          </li>
          <li>
            <a href="/Webinars/Politics/">Politics</a>
          </li>
        </ul>
      </div>
      <br />
    </React.Fragment>
  );
}
