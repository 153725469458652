import { createSlice } from "@reduxjs/toolkit";

const breakPointSlice = createSlice({
  name: "breakPoint",
  initialState: {
    breakPoint: 1
  },
  reducers: {
    setBreakPointValue: (state, action) => {
      state.breakPointValue = action.payload
    }
  }
});

export const { setBreakPointValue } = breakPointSlice.actions;
export default breakPointSlice.reducer;