import { createSlice } from "@reduxjs/toolkit";

const userRecordingLogsSlice = createSlice({
  name: "userRecordingLogs",
  initialState: {
    userRecordingLogs: [],
    userSubscribers: [],
    lastFetchedRecords: 0,
  },
  reducers: {
    setUserRecordingLogs: (state, action) => {
      state.userRecordingLogs = action.payload
    },
    setUserSubscribers: (state, action) => {
      state.userSubscribers = action.payload
    },
    setLastFetchedRecords: (state, action) => {
      state.lastFetchedRecords = action.payload
    }
  }
});

export const { setUserRecordingLogs, setUserSubscribers, setLastFetchedRecords } = userRecordingLogsSlice.actions;
export default userRecordingLogsSlice.reducer;