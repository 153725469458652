import { createSlice } from "@reduxjs/toolkit";

const userHostWebinarsSlice = createSlice({
  name: "userHostWebinars",
  initialState: {
    userHostWebinars: {}
  },
  reducers: {
    setUserHostWebinars: (state, action) => {
      state.userHostWebinars = action.payload
    }
  }
});

export const { setUserHostWebinars } = userHostWebinarsSlice.actions;
export default userHostWebinarsSlice.reducer;