import {
  Box,
  Typography,
  Modal,
} from "@mui/material";

const alertContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  background: "white",
  borderRadius: "8px",
  boxShadow: "10px 10px 18px black",
  border: "none",
};

const CustomAlert = ({ handleClose, open, message}) => {

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={alertContainer}>
          <Typography>
            {message}
          </Typography>
        </Box>
      </Modal>
    </>

  );
};

export default CustomAlert;