import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";
import ttiPolyfill from "tti-polyfill";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import ErrorBoundary from "./ErrorBoundary";

ReactGA.initialize("G-LXZTDKKW1N", {
  debug: false,
});

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.ga('send', 'timing', {
    name: "Time to Interactive",
    value: tti,
    eventCategory: "Load Performance",
  });
});

const callback = (list) => {
  list.getEntries().forEach((entry) => {
    ReactGA.ga('send', 'timing', {
      name: entry.name,
      value: entry.responseEnd,
      eventCategory: "First Meaningful Paint",
    });
  });
};

var observer = new PerformanceObserver(callback);
observer.observe({
  entryTypes: [
    "navigation",
    "paint",
    // "resource",
    // "mark",
    // "measure",
    // "frame",
    // "longtask",
  ],
});

// const history = createBrowserHistory();
// console.log(history)
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense>
          <Router>
            <ErrorBoundary>
              <App reactGA={ReactGA} />
            </ErrorBoundary>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();