import React, { useState } from 'react'
import { Button, createSvgIcon } from '@mui/material/'
import { useLocation, useNavigate } from 'react-router-dom'

const MoreIcon = createSvgIcon(
  <path d='M14.5 6.5L10 11 5.5 6.5 4 8l6 6 6-6-1.5-1.5z' />,
  'Home'
)

const showMoreLine = {
  top: '50%',
  borderTop: '1px solid black',
  width: '100%',
  position: 'absolute'
};

const showMoreLineContainer = {
  flexGrow: 1,
  position: 'relative',
  padding: 0
};

const showMoreContainer = {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  paddingTop: '0px',
};

const showMoreButtonContainer = {
  flexGrow: 0,
  paddingLeft: '1rem',
  paddingRight: '1rem'
};

const showMoreButton = {
  display: 'inline-flex',
  position: 'relative',
  alignItems: 'center',
  textAlign: 'inherit'
};

const lineReplacementForShowMoreButton = {
  flexGrow: 1,
  position: 'relative',
  padding: 0,
  paddingTop: '5vw'
};

export default function ShowMore({
  increaseWebinarsDisplayed,
  webinarAmount,
  webinarRows,
  isLive
}) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <>
      {isLive ?

        <div>
          <div style={showMoreContainer}>
            <div style={showMoreLineContainer}>
              <div style={showMoreLine}></div>
            </div>
            {webinarAmount >= Math.ceil(webinarRows) ? (
              pathname === '/' ? (
                <Button
                  onClick={() => {
                    increaseWebinarsDisplayed(true)
                    navigate('/ws/categories1')
                  }}
                >
                  Search More Webinars
                </Button>
              ) : (
                <div style={showMoreButtonContainer}>
                  <Button onClick={() => increaseWebinarsDisplayed()}>
                    <b>Show More</b>
                    <div>
                      <MoreIcon
                        style={{
                          color: 'black',
                          paddingTop: '50%',
                          paddingLeft: '20%'
                        }}
                      />
                    </div>
                  </Button>
                </div>
              )
            ) : (
              <div style={showMoreButtonContainer}>
                <Button onClick={() => increaseWebinarsDisplayed()}>
                  <b>Show More</b>
                  <div>
                    <MoreIcon
                      style={{
                        color: 'black',
                        paddingTop: '50%',
                        paddingLeft: '20%'
                      }}
                    />
                  </div>
                </Button>
              </div>
            )}
            <div style={showMoreLineContainer}>
              <div style={showMoreLine}></div>
            </div>
          </div>
        </div>

        :
        <div>
          <div style={showMoreContainer}>
            <div style={lineReplacementForShowMoreButton}>
              <div style={showMoreLine}></div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
