import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material/";
import AccountIcon from "@mui/icons-material/AccountCircleOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import { Modal, Box, Typography, Button } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AccountButton = ({ setInAccountPage, inAccountPage, userId, logOut }) => {
  const [dropDown, setDropDown] = useState(null);
  const [openM, setOpenM] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);
  const accountPath = useSelector((state) => state.user.accountPath);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const open = Boolean(dropDown);
  const userData = useSelector((state) => state.user.userData);
  const webinarData = useSelector((state) => state.event.allEvents);

  const navigate = useNavigate();

  const handleClick = (e) => {
    setDropDown(e.currentTarget);
  };
  const handleClose = () => {
    setDropDown(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData?.email) {
      let email = userData.email.toLowerCase();
      setUserEmail(email);
    }
  }, [userData?.email]);

  return (
    <>
      <div style={{ marginLeft: "calc(0px)", alignSelf: "center" }}>
        <IconButton
          aria-label="drop down menu for account button"
          sx={{ color: "black !Important" }}
          id="account-button"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <AccountIcon fontSize="large" />
        </IconButton>
        <Menu
          id="account-menu"
          anchorEl={dropDown}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "account-button",
          }}
          sx={{
            ".MuiList-root": {
              paddingTop: "0px",
            },
          }}
        >
          <div className="usernameDisplay">
            <p className="username-display-p">{userEmail}</p>
          </div>
          {webinarData && webinarData[0] && (
            <MenuItem
              onClick={() => {
                navigate(`${userData.userId}`);
                handleClose();
              }}
            >
              My Channel
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              navigate(`settings/user`);
              handleClose();
            }}
          >
            Settings
          </MenuItem>
          <MenuItem onClick={logOut}>Logout</MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default AccountButton;
