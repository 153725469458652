import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import breakPointSlice from "./slices/breakPointSlice";
import userHostDataSlice from "./slices/userHostDataSlice";
import userDataSlice from "./slices/userDataSlice";
import userHostWebinarsSlice from "./slices/userHostWebinarsSlice";
import userRecordingLogsSlice from "./slices/userRecordingLogsSlice";
import webinarSlice from "./slices/webinarSlice";
import webinarsSlice from "./slices/webinarsSlice";
import channelOffSuccessSlice from "./slices/channelOffSuccessSlice";
import hostDataSlice from "./slices/hostDataSlice";
import webinarEventSlice from "./slices/webinarEventSlice";
import resourceSlice from "./slices/webinarResourceSlice";
import conditionalUiSlice from "./slices/conditionalUiSlice";
import categoriesSlice from "./slices/categoriesFetch.js"
import competitorSlice from "./slices/competitorSlice.js"
import adBlockerDetectionSlice from "./slices/adBlockerDetectionSlice.js"
import dummyWebinarSlice from "./slices/dummyWebinarSlice.js"
import analyticsSlice from "./slices/analyticsSlice.js"
import meetingsSlice from "./slices/meetingsSlice.js"

const persistConfig = {
  key: "root",
  storage,
  version: 70,
  blacklist: ["webinars", "resources", "analytics"],
};

const rootReducer = combineReducers({
  breakPoint: breakPointSlice,
  channel: channelOffSuccessSlice,
  user: userDataSlice,
  userHostData: userHostDataSlice,
  userHostWebinars: userHostWebinarsSlice,
  userRecordingLogs: userRecordingLogsSlice,
  webinar: webinarSlice,
  webinars: webinarsSlice,
  hostData: hostDataSlice,
  event: webinarEventSlice,
  resources: resourceSlice,
  conditionalUi: conditionalUiSlice,
  categories: categoriesSlice,
  competitor: competitorSlice,
  detection: adBlockerDetectionSlice,
  dummyWebinar: dummyWebinarSlice,
  analytics: analyticsSlice,
  meetingData: meetingsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };