import { createSlice } from "@reduxjs/toolkit";

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    registeredData: [],
    viewedFilterType: "all"
  },
  reducers: {
    setRegisteredData: (state, action) => {
      state.registeredData = action.payload
    },
    setViewedFilterType: (state, action) => {
      state.viewedFilterType = action.payload;
    },
  }
});

export const { setRegisteredData, setViewedFilterType } = analyticsSlice.actions;
export default analyticsSlice.reducer;