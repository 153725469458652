import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { Grid, Box, Button, CircularProgress } from "@mui/material";
import ShowMore from "./ShowMore";
const WebinarGridContainer = lazy(() => import("./WebinarGridContainer"));
import InfiniteScroll from "react-infinite-scroll-component";
import webinarApi from "./utils/zoomCorderApi";
import { useSelector, useDispatch } from "react-redux";

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const WebinarsDisplayer = ({
  webinarData,
  isLive,
  home,
  logIn,
  promptConsent,
  token,
  user,
  hist,
  sched,
  libraryNotif,
  setLibraryNotif,
  reactGA,
  encryptedHostEmail,
  webinarCollection
}) => {
  const [showMore, setShowMore] = useState(1);
  const [sortedWebinarData, setSortedWebinarData] = useState([]);
  const [fetchedWebinarData, setFetchedWebinarData] = useState([]);
  const [lastWebinarId, setLastWebinarId] = useState("");

  const breakPointValue = useSelector(
    (state) => state.breakPoint.breakPointValue
  );
  const currentCategory = useSelector((state) => state.webinars.currentCategory);

  const incrementWebinars = (fromHome) => {
    if (fromHome) {
      setShowMore((prevValue) => prevValue - 1);
    } else {
      setShowMore((prevValue) => prevValue + 1);
    }
  };

  const checkForData = (data) => {
    let liveGraphicArray = [];
    let liveNoGraphicArray = [];
    let notLiveArray = [];
    if (data?.length > 0) {
      data.forEach((webinar) => {
        if (
          Date.now() > webinar.meetingStartTime &&
          Date.now() < webinar.meetingStartTime + 3600000 &&
          webinar.over == false
        ) {
          if (webinar.customImage || webinar.graphic || webinar.webinarBanner) {
            liveGraphicArray.push(webinar);
          } else {
            liveNoGraphicArray.push(webinar);
          }
        } else {
          notLiveArray.push(webinar);
        }
      });
      liveGraphicArray.push(...liveNoGraphicArray);
      liveGraphicArray.push(...notLiveArray);
    }
    return [...liveGraphicArray];
  };

  useEffect(() => {
    setLastWebinarId(webinarData[webinarData.length - 1]?.id);
    const sortedData = checkForData(webinarData);
    setSortedWebinarData(sortedData);
  }, [webinarData]);

  useEffect(async () => {
    let response;
    if (
      showMore + 1 >= (sortedWebinarData.length + fetchedWebinarData.length) / breakPointValue &&
      (sortedWebinarData.length + fetchedWebinarData.length) > 0
    ) {
      try {
        response = await webinarApi.getMoreWebinars(
          currentCategory,
          lastWebinarId
        );
        if (Object.keys(response).length > 0) {
          let categoryArray = response[currentCategory];
          let lastWebinar = categoryArray?.length - 1;
          setLastWebinarId(categoryArray[lastWebinar]?.id);

          const checkForDuplicates = () => {
            let cleanedArray = [];
            let holdArray = [];
            for (let i = 0; i < sortedWebinarData.length; i++) {
              holdArray.push(sortedWebinarData[i].topic);
            }
            for (let i = 0; i < categoryArray.length; i++) {
              if (holdArray.includes(categoryArray[i].topic)) {
                continue;
              } else {
                cleanedArray.push(categoryArray[i]);
              }
            }
            return cleanedArray;
          };
          let noDupes = checkForDuplicates();
          setFetchedWebinarData([...fetchedWebinarData, ...noDupes]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [showMore, sortedWebinarData]);

  const combinedWebinarData = useMemo(() => [...sortedWebinarData, ...fetchedWebinarData], [sortedWebinarData, fetchedWebinarData]);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <WebinarGridContainer
          arrayOfWebinarData={combinedWebinarData}
          showMore={showMore}
          isLive={isLive}
          home={home}
          logIn={logIn}
          promptConsent={promptConsent}
          token={token}
          user={user}
          hist={hist}
          sched={sched}
          libraryNotif={libraryNotif}
          setLibraryNotif={setLibraryNotif}
          reactGA={reactGA}
          encryptedHostEmail={encryptedHostEmail}
          webinarCollection={webinarCollection}
        />
      </Suspense>
      {/* <ShowMore
        increaseWebinarsDisplayed={incrementWebinars}
        webinarAmount={showMore}
        webinarRows={combinedWebinarData.length / breakPointValue}
        isLive={isLive}
      /> */}
    </>
  );
};

export default WebinarsDisplayer;