import { createSlice } from "@reduxjs/toolkit";

const resourceSlice = createSlice({
  name: "resources",
  initialState: {
    resources: [],
  },
  reducers: {
    setResources: (state, action) => {
      state.resources = action.payload;
    },
    addResource: (state, action) => {
      state.resources.push(action.payload);
    },
    updateResource: (state, action) => {
      const updatedResource = action.payload;
      const index = state.resources.findIndex(resource => resource.id === updatedResource.id);
      if (index !== -1) {
        state.resources[index] = updatedResource;
      }
    },
    deleteResource: (state, action) => {
      const id = action.payload;
      state.resources = state.resources.filter(resource => {
        if ( resource.id === id && resource.type === "videos" ) {
          URL.revokeObjectURL(resource.resource.video)
        }
        return resource.id !== id
      });
    },
  },
});

export const { setResources, addResource, updateResource, deleteResource } = resourceSlice.actions;

export default resourceSlice.reducer;