import {
  Box,
  Divider,
  Button,
  TextField,
  Modal,
  CircularProgress
} from "@mui/material";
import { styled } from '@mui/material/styles'

export const ProfileImageContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0 24px 0 0;
`;

export const NameCategoryContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 3vw;
  color: white;
  width: 60%;
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  rgba(113, 121, 126, .4), rgba(113, 121, 126, .6), rgba(113, 121, 126, .9));
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 1%;
  font-size: 1.5vw;
  color: white;
  margin-right: 10px;
`;

export const ProfileButtons = styled(Button)`
  width: 16vw !important;
  background: red !important;
  color: white !important;
  font-weight: 900 !important;
  font-size: 2vw;
  margin-left: 0.25vw !important;
  min-width: 55px !important;
  padding: 6px 12px !important;

  @media (max-width: 768px) {
    width: 160px;
    font-size: 2.3vw;
    padding: 4px 8px !important;
  }

  @media (max-width: 480px) {
    width: 8vw !important;
    font-size: 1.9vw;
  }
`;

export const UpcomingWebinarBackground = styled(Box)`
  margin-top: 40px;
  padding: 40px 0px 40px 0px;
  border-radius: 8px;
  background: linear-gradient(to bottom right, rgba(21, 118, 210, .8), rgba(21, 118, 210, .5), rgba(21, 118, 210, .2));
`;

export const CategorySubscribeEmail = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 3vw;
  width: 37vw;
  margin-top: 5px;
  min-width: 86px;
`;

export const Category = styled(Box)`
  display: flex;
  align-items: flex-end;
  margin-left: 1vw;
  margin-bottom: -.3vw;
  font-size: 2vw;
`;

export const RequestModal = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.91),
  0 5px 40px -10px rgba(31, 44, 60, 0.91);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  background: white;
  padding: 20px 40px 20px 40px  !Important;
   & >:not(.form-title-container):not(style) {
    margin: 10px;
  }
`;
export const RequestModals = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  border-radius: 8px;
  box-shadow: 5px 10px 18px black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background: white;
  padding: 20px 20px 20px 20px  !Important;
   & >:not(style) {
    margin: 10px;
  }
`;

export const RegistrationModal = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.91),
  0 5px 40px -10px rgba(31, 44, 60, 0.91);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px 40px 20px 20px  !Important;
   & >:not(.form-title-container):not(style) {
    margin: 10px;
  }
`;