import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';



const BackToTopButton = () => {

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      let scrollAmount = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(scrollAmount > 300);
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <div className="scroll-top" >
          <Button variant="contained"
            onClick={scrollToTop}
            sx={{ background: "rgba(113, 121, 126, .5) !Important" }}
          >
            Back To Top
          </Button>
        </div>
      )}
    </>
  )
}

export default BackToTopButton;